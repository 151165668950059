.mdm--category-graph-container {
  margin-bottom: 1.5vh;
  height: 52.5vh;
  .mdm--landing-page-item {
    padding-left: 2.25%;
  }
  .mdm--category-graph-body {
    padding: 1vh 0;
    height: 94% !important;
    background: #fff;
    .mdm--row {
      height: 100%;
      justify-content: space-around;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      .mdm--kpi-graph-dropdown {
        margin: auto;
        z-index: 1;
        font-family: Arial;
        font-style: normal;
        font-weight: 400;
        font-size: 0.75vw;
        line-height: 20px;
        color: #595959;
        #mdm--kpi-graph-dropdown-item {
          background: #fff;
          border: 0;
          box-shadow: none;
        }
      }
      .k-chart-donut-center {
        span {
          font-size: 0.875rem;
          font-family: Arial;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          color: #404040;
        }
      }
      .k-chart,
      .k-sparkline,
      .k-stockchart {
        font-size: 1vw;
      }
    }
  }

  .margin {
    margin: 25px;
  }
}

// ===================Semi Donut Chart model-2========================

.doughnut_container {
  width: 100%;
  height: 100%;
  padding-top: 2vh;
  .no-records {
    text-align: center;
    font-size: 0.75vw;
    font-family: Arial;
  }
  .doughnut_container-item:nth-child(even) {
    .doughnut {
      left: 4vw;
    }
    .doughnut-legend {
      justify-content: start;
    }
  }
  .doughnut_container-item:nth-child(odd) {
    .doughnut {
      left: 1vw;
    }
    .doughnut-legend {
      justify-content: end;
      span {
        text-align: end;
        > div {
          display: inline;
        }
      }
    }
  }
  .start_line {
    height: 0.73vw;
    width: 13%;
    border: none;
    background: #29becd;
    opacity: unset;
    position: absolute;
    margin: 0.1vw 0;
    animation: startLineLoadAnimation 1s ease-in backwards;
    animation-delay: 1s;
    right: 13%;
  }
}
.end_line {
  height: 0.73vw;
  width: 50%;
  border: none;
  background: #1476b5;
  opacity: unset;
  margin: 0;
  animation: endLineLoadAnimation 1s ease-in backwards;
  animation-delay: 1s;
  position: relative;
  top: -11.725vw;
  left: 50%;
}

.doughnut_container-item {
  display: flex;
  height: 5.2vw;
  position: relative;
  &.item-1 {
    top: -2.2vw;
  }
  &.item-2 {
    top: -4.4vw;
  }
  &.item-3 {
    top: -6.55vw;
  }
  &.item-4 {
    top: -8.75vw;
  }
  &.item-5 {
    top: -10.9vw;
  }
}
.doughnut-legend {
  width: 30%;
  height: 100%;

  .k-i-information {
    margin-top: -0.35vh;
    font-size: 1vw;
  }

  .k-i-information-right {
    margin-left: 0.5vw;
    margin-top: -0.8vh;
  }
  .k-i-information-left {
    margin-right: 0.5vw;
  }
}
.doughnut-item {
  width: 40%;
  height: 100%;
}
.doughnut-legend {
  display: flex;
  align-items: center; /* align vertical */
  padding: 0 0.5rem;
  span {
    font-family: Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75vw;
    line-height: 0.85vw;
    color: #404040;
  }
}

.doughnut {
  display: block;
  width: 5vw;
  height: 5vw;
  position: relative;
  background: transparent;
  border-radius: 100%;
  top: 0.1vw;
  animation: fillAnimation 1s ease-in;
}

.doughnut-0 {
  border: 0.75vw solid #29becd;
  border-top-left-radius: 1px;
}
.doughnut-1 {
  border: 0.75vw solid #175670;
}
.doughnut-2 {
  border: 0.75vw solid #f68d2f;
}
.doughnut-3 {
  border: 0.75vw solid #ee4657;
}
.doughnut-4 {
  border: 0.75vw solid #f1b31c;
}
.doughnut-5 {
  border: 0.75vw solid #1476b5;
  border-bottom-right-radius: 1px;
}
.doughnut-right {
  border-left: 0.75vw solid transparent;
}
.doughnut-left {
  border-right: 0.75vw solid transparent;
}
.loading .doughnut-right,
.loading .doughnut-left {
  border: 0.75vw solid rgba(66, 66, 66, 0.2);
  border-radius: 100%;
}

.loading .doughnut {
  border: 0.75vw solid rgba(66, 66, 66, 0.2);
  border-radius: 50%;
  border-top: 0.75vw solid rgba(66, 66, 66, 0.25);

  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.circle {
  background: #29becd;
  border-radius: 50%;
  width: 3vw;
  height: 3vw;
  display: block;
  margin: auto;
  top: 0.2vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .circle_label {
    font-family: Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75vw;
    line-height: 1vw;
    color: #ffffff;
  }
  &.circle_0 {
    background: #29becd;
  }
  &.circle_1 {
    background: #175670;
  }
  &.circle_2 {
    background: #f68d2f;
  }
  &.circle_3 {
    background: #ee4657;
  }
  &.circle_4 {
    background: #f1b31c;
  }
  &.circle_5 {
    background: #1476b5;
  }
}
.loading .circle {
  background: #d3d3d3;
}

.no-records-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .no-records {
    font-size: 0.75vw;
    font-family: Arial;
  }
}

@keyframes fillAnimation {
  0% {
    transform: rotate(90deg);
    border-color: #d3d3d3;
    border-radius: 50%;
  }
}

@keyframes startLineLoadAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 11%;
  }
}

@keyframes endLineLoadAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 50%;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.category-tooltip {
  span {
    color: #fff;
    font-family: Arial;
    font-size: 0.75vw;
  }
  &::before {
    content: "\2022";
    color: #fff;
    font-weight: bold;
    display: inline-block;
    width: 1vw;
  }
}
