.mdm--landing-page {
  display: grid;
  display: flex;
  justify-content: space-between;
  padding: 1.5vh 0;
  height: 100%;
  .mdm--landing-page-item {
    .mdm--landing-page-item-title {
      font-family: Shell Font Bold;
      // font-size: 18px;
      font-size: 0.95vw;
      // line-height: 18px;
      line-height: 0.83vw;
      // margin-left: 1rem;
      margin-left: 0;
      margin-bottom: 1.25vh;
    }
    .mdm--landing-page-item-body {
      box-shadow: 0px 2px 10px -2px #00000026;
      background: #ffffff;
      margin: 0.5vh 0;
      // height: calc(100% - 1rem)
    }
    .mdm--sites-list-body {
      height: 84.5vh;
      & > .k-tabstrip {
        width: 100%;
      }
    }
  }
  .mdm--lp-sites-list-section {
    width: 66vw;
    .mdm--site-list-container {
      height: 100%;
    }
    &.expandable {
      /* overflow set to hidden to hide the expanded text */
      overflow: hidden;
      /* all style changes will ease-in-out for 1s */
      -moz-transition: all 1s ease-in-out;
      -ms-transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
      -webkit-transition: all 1s ease-in-out;
      transition: all 1s;
    }
    &.expanded {
      width: 100%;
    }
  }
  .mdm--lp-graph-action-section {
    width: 32%;
    min-width: 25rem;
  }
  .mdm--lp-new-recent-section {
    width: 24vw;
  }
}
