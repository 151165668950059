.mdm--data-grid {
  .row {
    display: flex;
    &.button-container {
      button {
        width: 50%;
      }
    }
  }

  .k-grid {
    border: 0;
    .k-grid-header {
      background: #e9ecf1;
      .k-grid-header-wrap {
        border-right: none;
      }
    }
    table {
      width: 100% !important;
      thead {
        tr {
          th {
            font-family: Arial;
            font-weight: 600;
            // font-size: 14px;
            font-size: 0.8vw;
            border-width: 0;
            .k-link {
              // padding: 6px 14px;
              padding: 0.25vw 0.8vw;
              .k-column-title {
                line-height: 1.2vh;
                // height: 2rem;
                height: 1.75vw;
                white-space: normal;
                display: inline-flex;
                align-items: center;
              }
            }
          }
        }
      }
      tbody {
        tr {
          background: #fff;
          td,
          span.mdm-grid-td-text {
            font-family: Arial;
            // font-size: 14px;
            font-size: 0.75vw;
            color: #404040;
            border: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            a {
              color: #0d6efd !important;
              text-decoration: underline !important;
              cursor: pointer !important;
            }
          }
          &:not(.k-detail-row):hover {
            background-color: #ededed;
          }
          td {
            div {
              display: initial;
            }
            padding: 0.25vw 0.83vw;
          }
        }
      }
    }
  }
  .k-pager-wrap .k-link {
    // font-size: 12px !important;
    font-size: 0.75vw !important;
    text-decoration: none !important;
  }
  .k-grid-pager {
    font-size: 0.75vw !important;
    padding: 2px;
  }
  .k-pager-numbers .k-link {
    // min-width: calc(1.4285714286em + 6px) !important;
    // height: calc(1.4285714286em + 6px) !important;
    min-width: 1vw !important;
    height: 3vh !important;
  }
  .k-pager-info.k-label {
    font-size: 0.75vw;
  }
  .k-icon {
    font-size: 2vh;
  }
  .k-input-inner {
    font-size: 2vh;
  }
}
