.mdm--session-expired-portal {
  .k-actions {
    margin: 0;
    width: 40%;
    margin-left: 60%;
    .k-button {
      font-weight: 600;
      color: #fff;
      background-color: rgba(0, 60, 135, 1);
      &:hover {
        background-color: rgba(0, 60, 135, 0.9);
      }
    }
  }
  .k-dialog-title,
  .k-button,
  p {
    font-family: Arial;
  }
  .k-dialog-titlebar {
    background: #f9f9f9;
  }
  p {
    font-size: 1vw;
  }
}
