.k-notification-group {
  margin-top: 4vw;
  .k-notification-wrap {
    height: 6vw;
    width: auto;
    min-width: 35vw;
    max-width: 40vw;
    .k-icon {
      height: 6vw;
      font-size: 2vw;
      color: #fff !important;
      &.k-i-close {
        height: 1vw;
        font-size: 1vw;
        color: #fff !important;
        text-decoration: none !important;
      }
    }
    .k-notification-content {
      font-size: 1vw;
      margin: auto;
      margin-left: 1vh;
    }
    .notification-message {
      font-size: 1vw;
      line-height: 1vw;
      font-family: Arial;
      color: #fff;
    }
    .notification-title {
      font-size: 1.2vw;
      line-height: 2vw;
      font-family: Arial;
      font-weight: 600;
      color: #fff;
    }
  }
}
