.preaaproval-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.preapproval-card-container {
  height: 100%;
  width: 100%;
}
.preapproval-card-body {
  display: flex;
  flex-flow: wrap;
  //  margin: 0;
  padding: 0;
}
// .preApproval-multiselect{
// font-size: 2vh;
// width: 28vh;
// }
.preapproval-card {
  height: 80vh;
  width: 50%;
  margin: 1vh;
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  overflow: scroll;
}
.preApproval-checkbox {
  width: 15vh;
  font-size: 1.5vh;
  margin: 0vh 3vh 0vh 3vh;
}
.preapproval-dropdown-container {
  width: 45%;
  margin: 2vh 0vh 0vh 4vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.preapproval-dropdown-title {
  font-size: 2vh;
  font-weight: 500;
  display: flex;
  width: 44vh;
}
.preapproval-list-title {
  width: 42%;
  line-height: 1vw;
  font-family: Arial;
  font-weight: 700;
  font-size: 0.75vw;
  word-break: break-word;
  color: #404040;
  display: inline-flex;
  align-items: center;
}

.preApproval-backtick {
  display: inline-flex;
  align-items: center;
  font-size: 2vh;
}
.Button-container-preapproval {
  display: flex;
  justify-content: flex-end;
  width: 87%;
  align-items: flex-end;
}
.buttons-preapproval-button {
  font-family: Arial;
  font-size: 0.85vw;
  font-weight: 700;
  height: 4vh;
  width: 9vh;
  background-color: #003c88;
  color: #ffffff !important;
  border: none;
}
.buttons-preapproval-button:hover {
  background-color: #0d6efd;
}
.mdm--site-list-tabs-change-request .k-tabstrip-items-wrapper ul .k-active {
  font-family: Arial;
  font-weight: 600;
  color: #003c88;
  border-bottom: 0.15vw solid;
  border-bottom-color: #003c88 !important ;
}
.mdm--site-list-tabs-change-request
  .k-tabstrip-items-wrapper
  ul
  .k-active
  .k-link
  span {
  color: #003c88;
}
.preApproval-dropdown-country {
  width: 18vh;
  font-size: 2vh;
  margin: 0vh 2vh 0vh 1vh;
  .k-input-value-text {
    padding-left: 0.25vw;
  }
}
.preApproval-dropdown-country button {
  height: 4vh;
}
.dropdown-country-container {
  display: flex;
  align-items: center;
  .dropdown-country-preapproval-title {
    width: 23vh;
  }
}
