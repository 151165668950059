.mdm--tooltip-change-history {
  .k-callout {
    color: transparent;
    margin-left: 0px;
    margin-top: -70%;
    left: 4px;
    width: 1.8vw;
    height: 1.8vw;
    background-color: #282828;
    clip-path: polygon(0 0, 0 100%, 100% 49%);
  }
  .change-history-container-for-hover {
    position: absolute;
    width: 17.31vw;
    right: 4px;
    bottom: -27vh;
    height: auto;
    padding: 10px;
    opacity: 1;
    background-color: #282828;
  }
}
