section.mdm-sd-right-section {
  .mdm--site-detail {
    &.mdm--sd-basic-details-top-section {
      .k-tabstrip-items-wrapper {
        margin-top: -1.9rem;
        span.k-link {
          padding-bottom: 0;
          padding-left: 0;
        }
      }
      .mdm--sd-details-child-tab {
        .k-tabstrip-items-wrapper {
          margin-top: 0;
        }
      }
      .not-applicable {
        font-family: Shell Font Bold;
        font-size: 0.75vw;
        // line-height: 32px;
        padding-left: 0.5rem;
      }
    }
  }
}
