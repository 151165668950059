$Shell_Font_Bold: "Shell Font Bold";
$Shell_Font_Light: "Shell Font Light";
$Shell_Font_Heavy: "Shell Font Heavy";
$Shell_Font_Medium: "Shell Font Medium";
$Shell_Font_Book: "Shell Font Book";

@font-face {
  font-family: $Shell_Font_Light;
  src: url(../../assets/fonts/TTF/ShellLight.ttf) format("truetype");
}

@font-face {
  font-family: $Shell_Font_Bold;
  src: url(../../assets/fonts/TTF/ShellBold.ttf) format("truetype");
}

@font-face {
  font-family: $Shell_Font_Heavy;
  src: url(../../assets/fonts/TTF/ShellHeavy.ttf) format("truetype");
}

@font-face {
  font-family: $Shell_Font_Medium;
  src: url(../../assets/fonts/TTF/ShellMedium.ttf) format("truetype");
}

@font-face {
  font-family: $Shell_Font_Book;
  src: url(../../assets/fonts/TTF/ShellBook.ttf) format("truetype");
}

h1 {
  font-size: 2.5vw;
}

h2 {
  font-size: 2.3vw;
}

h3 {
  font-size: 2.1vw;
}

h4 {
  font-size: 1.8vw;
}

h5 {
  font-size: 1.5vw;
}

h6 {
  font-size: 1.2vw;
}

p {
  font-size: 0.729vw;
  margin-bottom: 0;
}
