.mdm--site-list-container {
  .mdm--sites-list-body {
    .mdm--site-list-tabs {
      width: 100%;
      .k-tabstrip-items-wrapper {
        border-bottom: none;
        // margin: 0 1rem;
        margin: 0 0.83vw;
        .k-tabstrip-items {
          border: 0;
          // padding-right: 15%;
          padding-right: 10%;
          justify-content: space-between;
          // border-bottom: 2px solid #0000001f;
          border-bottom: 0.1vw solid #0000001f;
        }
        .k-item {
          color: #000;
          border-left: none;
          border-right: none;
          span.k-link {
            padding: 0.2vw 0.4vw;
            padding-left: 0;
            .mdm--lp-sites-list-tab-title {
              font-family: Arial;
              // font-size: 1rem;
              // margin-right: 0.5rem;
              font-size: 0.83vw;
              margin-right: 0.5vw;
            }
            .mdm--dot {
              font-family: Arial;
              font-weight: 600;
              // font-size: 10px;
              // line-height: 2rem;
              // height: 2rem;
              // width: 2rem;
              font-size: 0.55vw;
              line-height: 2vw;
              height: 2vw;
              width: 2vw;
              color: #fff;
              background-color: #adadad;
              border-radius: 50%;
              display: inline-block;
              text-align: center;
            }
          }
          &.k-active {
            font-family: Arial;
            font-weight: 600;
            color: #003c88;
            // .mdm--lp-sites-list-tab-title {
            // border-bottom: 2px solid !important;]
            border-bottom: 0.15vw solid !important;
            border-bottom-color: #003c88 !important;
            // }
            .mdm--lp-sites-list-tab-title {
              color: #003c88 !important;
            }
            .mdm--dot {
              // border-bottom: 2px solid !important;
              // border-bottom-color: #003c88 !important;
              // color: #fff;
              background-color: #003c88 !important;
            }
          }
        }
      }
      .k-content {
        // padding: 0 1rem;
        padding: 0 0.83vw;
        border: none;
        overflow: hidden;
        .k-animation-container,
        .k-child-animation-container,
        .mdm--data-grid,
        div[role="tabpanel"] {
          height: 100%;
          width: 100%;
        }
      }
      // .mdm-grid-cell-empty {
      //   text-align: center;
      // }
      .mdm-grid-custom-td {
        > div {
          display: inline-block;
        }

        .k-icon {
          float: right;
          // background-size: 1rem;
          background-size: 0.83vw;
          background-repeat: no-repeat;
          // top: 0.25rem;
          top: 0.1vw;
          width: 1vw !important;
          height: 1vw;
          &.k-i-check-circle-icon-circle {
            background-image: url("./../../../../assets/images/icons/check-circle-icon.svg");
          }
          &.k-i-warning-icon-circle {
            background-image: url("./../../../../assets/images/icons/warning-filled-icon.svg");
          }
          &.k-i-warning-red-icon-circle {
            background-image: url("./../../../../assets/images/icons/warning-filled-red-icon.svg");
          }
          &.k-i-information-icon-circle {
            background-image: url("./../../../../assets/images/icons/information-filled-icon.svg");
          }
          &.k-i-information-red-icon-circle {
            background-image: url("./../../../../assets/images/icons/information-filled-red-icon.svg");
          }
        }

        &.mdm--action-item-active span {
          color: #2a9041;
        }
        &.mdm--action-item-work_in_process span,
        &.mdm--action-item-invalid span,
        &.mdm--action-item-closed_temporarily span,
        &.mdm--action-item-not_specified span,
        &.mdm--action-item-recycle_bin span {
          color: #eb8705;
        }
        &.mdm--action-item-closed_permanently span,
        &.mdm--action-item-landbank span,
        &.mdm--action-item-completed_not_opened span,
        &.mdm--action-item-non-retail_shell_location span {
          color: #a82424;
        }
      }
    }
    .k-pager-sizes .k-dropdownlist {
      height: 3vh;
      width: 6vh;
    }
    .k-pager-sizes .k-dropdownlist button {
      height: 2.5vh;
    }
  }
  #expandBtn {
    height: 2rem;
  }
  .k-grid-norecords {
    height: 62vh;
  }
}

.mdm--change-request-dropdown {
  font-size: 1vw !important;
  .k-input-button {
    height: 4vh;
  }
}
.k-dialog-titlebar {
  background: #f9f9f9;
  // color: black !important;
}
.k-dialog-titlebar div button span {
  color: black !important;
}
.k-dialog-titlebar div {
  color: black;
}
.buttons-change-request-list-button span {
  color: #003c88;
  // text-decoration: underline;
}
.change-request-list-dialog .k-grid table thead tr th .k-link .k-column-title {
  align-items: center;
  display: inline-flex;
  height: 1.75vw;
  line-height: 1.2vh;
  white-space: normal;
  font-weight: 700;
}
.status_tag {
  background: #d45f53;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  padding: 4px 6px 4px 8px;
  font-size: 0.8em;
  border-radius: 4px/4px;
}
.status_tag_rejected{
  background: #ADB2AF;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 4px 4px 4px 1px;
  font-size: 0.8em;
  border-radius: 4px/1px;
}
.status_tag_approved{
  background: #2a9041;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 4px 4px 4px 1px;
  font-size: 0.8em;
  border-radius: 4px/1px;
}
.status_tag_open{
  background: #eb8705;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 4px 4px 4px 6px;
  font-size: 0.8em;
  border-radius: 4px/1px; 
}
.mdm--data-grid-button-batch {
  :hover {
    background-color: #f9f9f9;
  }
  span {
    color: black;
    font-size: 1.5vh;
    padding: 0.5vh;
  }
  button {
    height: 3.9vh;
    span {
      color: black;
      width: 1vh;
    }
  }
}
.mdm--data-grid-button-batch {
  width: 6.5vw !important;
  height: 1.8vw;
  margin-right: -16.4vw;
}

.change-request-button-batch{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.mdm--session-expired-portal-aproved {
  .k-actions {
    margin: 0;
    width: 100%;
    .k-button {
      font-weight: 600;
      color: #fff;
      background-color: rgba(0, 60, 135, 1);
      width: 16vh;
      margin: 9px;
      &:hover {
        background-color: rgba(0, 60, 135, 0.9);
      }
    }
  }
  .k-dialog-title,
  .k-button,
  p {
    font-family: Arial;
  }
  p {
    font-size: 1vw;
  }
}
textarea {
  font-size: 0.8rem;
  letter-spacing: 1px;
}

textarea {
  padding: 10px;
  max-width: 100%;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
}
.mdm--details-view-component-message-error {
  line-height: 1vw;
  margin-top: 0;
  color: red;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 0.85vw;
}

.configuration-checkbox-master input{
  left: 0.3vh;
}

.success-open{
  color: #eb8705 !important;
}
