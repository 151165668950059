$border-radius: 2px;
$primary: #003c88;
$secondary: #ffffff;
$info: #3e80ed;
$success: #5ec232;
$warning: #fdce3e;
$error: #d51923;
$body-text: #424242;
$body-bg: #ffffff;
$headings-text: #272727;
$subtle-text: #666666;
$disabled-text: #8f8f8f;
$component-text: #424242;
$component-bg: #ffffff;
$base-text: #424242;
$base-bg: #fafafa;
$hovered-text: #003c88;
$hovered-bg: #becde9;
$selected-text: #ffffff;
$selected-bg: #003c88;
$kendo-button-text: #424242;
$kendo-button-bg: #f5f5f5;
$link-text: #003c88;
$link-hover-text: #003c88;
$series-a: #003c88;
$series-b: #ffe162;
$series-c: #4cd180;
$series-d: #4b5ffa;
$series-e: #ac58ff;
$series-f: #ff5892;
@import "bootstrap/dist/css/bootstrap.css";
@import "~@progress/kendo-theme-default/dist/all.scss";

@import "./ui/styles/typography.scss";
@import "./ui/styles/colors.scss";
// .k-pager-numbers .k-link.k-state-selected {
//   background-color: #003c88 !important;
//   color: #ffffff !important;
//   &:hover {
//     background-color: #0050e6 !important;
//   }
// }

// .k-pager-numbers .k-link {
//   color: #003c88 !important;
//   &:hover {
//     background-color: #becde9 !important;
//   }
// }
// .k-radio:checked,
// .k-radio.k-checked {
//   border-color: #003c88 !important ;
//   background-color: #003c88 !important;
//   color: #ffffff !important;
// }

.k-radio:checked:focus,
.k-radio.k-checked.k-state-focus,
.k-radio.k-checked.k-focus {
  box-shadow: 0 0 0 2px rgba(107, 88, 255, 0.3) !important;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #404040;
}
a {
  text-decoration: none;
}
.mdm--row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.mdm--grid-filter {
  margin-right: 1rem;
  margin-top: 1rem;
  .mdm--grid-filter-label {
    font-family: Arial;
    font-weight: 600;
    font-size: 1vw;
  }
}

.mdm-submit-btn {
  border-radius: 0 !important;
  &.primary-btn,
  &.primary-btn .k-button-text {
    background-color: #003c88;
    color: #ffffff;
    font-family: Arial;
    font-size: 1vw;
    &:hover {
      background-color: #0050e6;
    }
  }
  &.secondary-btn {
    background-color: #393939;
    color: #ffffff;
    &:hover {
      background-color: #474747;
    }
    .k-button-text {
      color: #ffffff;
    }
  }
}

.mdm--grid-toolbar-filter-popup {
  padding-bottom: 0 !important;
  .mdm--grid-toolbar-filter-items {
    margin: 0.5vw 0.5vw;
    padding: 0.5vw 0.5vw;
    width: 19vw;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    .mdm--grid-filter {
      margin: 0 0 1vh 0;
      .mdm--grid-filter-label {
        font-family: Arial;
        font-weight: 600;
        font-size: 0.9vw;
      }
      .k-checkbox-label {
        font-family: Arial;
        font-size: 0.9vw;
      }
      .mdm--multiselect-dropdown {
        .k-chip-content {
          padding: 0;
          .k-chip-label {
            line-height: 4vh;
            font-size: 1vw;
          }
        }
        .k-input-values::before {
          position: absolute;
          content: "▼";
          // top: 0.25vw;
          right: 0;
          height: 1.5w;
          width: 1.25vw;
        }
        input {
          font-size: 0.8vw;
          font-family: Arial;
        }
      }
    }
  }
  .button-container {
    button {
      width: 50%;
    }
  }
}

.mdm--grid-toolbar-export-popup {
  width: 15.5rem;
  padding-bottom: 0 !important;
  .mdm--grid-toolbar-export-content {
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 0.5rem;
    .mdm--grid-filter {
      margin-right: 0;
      margin-top: 0;
    }
    .export-warming-msg {
      font-family: Arial;
      font-size: 0.8vw;
    }
  }
  .button-container {
    button {
      width: 100%;
    }
  }
}
.mdm--tooltip-change-history {
  .ad {
    width: 100%;
    height: auto;
    margin: 0% 0 0% 0;
    /* border: 1px solid yellow; */
    display: flex;
    align-items: center;
    justify-content: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.26);
  }
  .image {
    width: 15%;
    height: 4vh;
    margin: 1% 1% 2% 1%;
    float: left;
  }
  .address-site-com {
    width: 100%;

    height: auto;
    font-size: 0.849vw;
    font-weight: 700;
    padding: 3% 1% 4% 3%;
    color: #ffff;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
  }
  .change-history-table-data {
    width: 100%;
    height: auto;
    margin: 10px;
    tr > :nth-child(1) {
      width: 38%;
      height: auto;
      font-family: Arial;
      font-style: normal;
      font-weight: 700;
      font-size: 0.75vw;
      // line-height: 35px;
      padding: 1px;
      color: #ffffff;
    }
    tr > :nth-child(2) {
      height: auto;
      font-family: Arial;
      font-style: normal;
      font-weight: 100;
      font-size: 0.75vw;
      // line-height: 18px;
      padding: 1px;
      padding-left: 10px;
      color: #ffffff;
    }
    .vertical-bar {
      float: right;
      font-size: 0.85vw;
      color: #ffffff;
    }
  }
}
.k-breadcrumb-link {
  box-shadow: none !important;
}

.k-state-invalid {
  border-color: #d51923 !important;
}

.visibility-hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

.edit-value-changed {
  border-right: 0.25rem solid #6dd569 !important;
}

.k-notification-group {
  z-index: 10;
}

li {
  .k-list-item-text {
    font-size: 0.75vw;
  }
}

.mdm--sidepanel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  .mdm-sidepanel-body {
    background: #fff;
    display: inline-block;
    height: calc(100% - 7vh);
    position: absolute;
    right: 0;
    top: 8vh;
    width: 25vw;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    .mdm--sidepanel-title {
      font-family: Arial;
      margin: 1rem 0.5rem;
      justify-content: space-between;
      h6 {
        font-weight: 600;
        color: #404040;
      }
      button {
        padding: 0;
        background: #fff;
        border: none !important;
        cursor: pointer;
      }
    }
    .mdm--sidepanel-body-content {
      margin: 1rem 0.5rem;
      font-family: Arial;
      font-size: 1vw;
      overflow: auto;
      height: 90%;
    }
    .mdm--sidepanel-body-content-item {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #d3d3d3;
    }
    .sidepanel-no-notification-text {
      font-family: Arial;
      font-size: 1vw;
      padding-left: 0.5rem;
      text-align: center;
    }
    .notification-loader {
      width: 98% !important;
    }
    .notification-status {
      font-family: Arial;
      font-weight: 600;
      font-size: 0.85vw;
      &.failed,
      &.failed .k-icon,
      &.failed span {
        color: #a82424;
      }
      &.success,
      &.success span,
      &.success .k-icon {
        color: #2a9041;
      }
      &.in_progress,
      &.in_progress span,
      &.in_progress .k-icon {
        color: #ffc727;
      }
    }
  }
  &.hidden,
  .hidden {
    transform: scale(0, 0);
  }
  &.visible,
  .visible {
    transform: scale(1, 1);
    overflow: hidden;
    transition: transform 400ms ease-in-out 0ms;
  }
}

.k-list-item.k-selected,
.k-list-item.k-selected .k-list-item-text,
.k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-td.k-selected:hover .k-link {
  background-color: #00377d !important;
  color: #fff !important;
}

.k-calendar .k-calendar-td.k-selected .k-link {
  color: #fff !important;
  background-color: #003c88 !important;
}

.k-calendar .k-calendar-view .k-today,
.k-calendar .k-nav-today,
.k-grid-header .k-i-sort-asc-sm,
.k-i-sort-desc-sm,
.k-pager-numbers .k-link {
  color: #003c88 !important;
}

.k-radio:checked,
.k-radio.k-checked {
  border-color: #003c88 !important;
  color: white !important;
  background-color: #003c88 !important;
}

.k-pager-numbers .k-link.k-state-selected,
.k-pager-numbers .k-link.k-selected {
  color: #003c88 !important;
  background-color: rgba(0, 60, 136, 0.2) !important;
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover,
.k-pager-numbers .k-link.k-hover {
  color: #003c88 !important;
  background-color: rgba(0, 60, 136, 0.08) !important;
}

.k-pager-numbers .k-link:focus,
.k-pager-numbers .k-link.k-state-focus,
.k-pager-numbers .k-link.k-focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px rgb(123 190 231 / 12%) !important;
}

.k-tooltip-content,
.k-button-solid-primary .k-button-text,
.k-window-title {
  font-family: Arial;
  color: #ffffff;
}

.k-dialog-actions > .k-icon-button.k-button-md > .k-button-icon {
  color: #ffffff;
}

a {
  color: #0d6efd !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.k-notification-error * {
  color: #ffffff;
}

//scrollbar style
/* width */
/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.k-radio-label {
  font-size: 0.75vw;
  font-family: Arial;
}
.k-button-text {
  font-size: 1vw;
  font-family: Arial;
}
.k-radio-md {
  width: 1vw !important;
  height: 1vw !important;
}
.k-loading-color {
  background-color: transparent !important;
  opacity: 0;
}

.k-i-arrow-s {
  width: 3vh !important;
  height: 5vh !important;
}
.k-icon {
  width: 1vh !important;
}

.mdm--sidepanel {
  .k-icon {
    width: 1em !important;
    margin-right: 0.5vw;
  }
}

.resolution_msg {
  height: 100vh;
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: center;
  font-size: 2vw;
  text-align: center;
}

.mdm-dialog {
  .k-window-actions {
    margin: 0;
  }
  &.unauthorised_dialog_box {
    p {
      font-family: Arial;
      font-size: 0.85vw;
      color: #000;
    }
    a {
      text-decoration: none !important;
      font-weight: 600;
      color: #0875e2 !important;
      padding-bottom: 2px;
      &:hover {
        border-bottom: #0875e2 2px solid;
      }
    }
  }
}
.cofiguration-country-dropdown {
  .k-list-optionlabel {
    font-size: 0.75vw;
    padding: 4px !important;
  }
}
.k-timeselector {
  .k-time-footer.k-actions {
    button {
      padding: 0 0.15vw;
    }
    .k-button-solid-primary {
      border-color: #0875e2;
      color: white;
      background-color: #0875e2;
    }
    .k-button-text {
      font-size: 0.75vw;
    }
  }
}
