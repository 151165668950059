.mdm--whats-new-container {
  height: 18rem;
  > .mdm--row {
    padding-top: 0.75rem;
  }
  .mdm--whats-new-total-tag {
    height: 1.5rem;
    line-height: 1.25rem;
    border-radius: 12px;
    padding: 0 8px;
    margin-top: -0.25rem;
    margin-left: 0.75rem;
    background: #003c88;
    color: #fff;
  }
  .mdm--whats-new-body {
    height: 14rem;
    margin-top: 0.5rem !important;
    .mdm--new-item {
      padding: 0.5rem 0.75rem;
      min-height: 5rem;
      .mdm--new-item-button {
        width: 98%;
        display: flex;
        justify-content: flex-start;
        min-height: 4.5rem;
        background: transparent;
        border: none;
        &::after {
          content: "";
          display: block;
          opacity: 1;
          border: solid #000000;
          border-width: 0 3px 3px 0;
          padding: 3px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          height: 0.25rem;
          width: 0.25rem;
          top: 2rem;
          left: 96%;
        }
        .k-button-text {
          text-align: left;
          .mdm--new-item-button-div {
            margin-left: 1rem;
            &.mdm--new-item-button-title {
              font-family: Arial;
              font-weight: 600;
              font-size: 15px;
              line-height: 18px;
              white-space: normal;
              width: 85%;
            }
            &.mdm--new-item-button-desc {
              font-family: Arial;
              font-size: 12px;
              line-height: 14px;
              white-space: normal;
              width: 85%;
            }
          }
        }
      }
      &:first-child {
        border-bottom: 1px solid #0000001f;
      }
    }
    .mdm--whats-new-more-btn {
      margin-left: 1.5rem;
      border: 0;
      padding: 0;
      width: 85%;
      height: 2.25rem;
      background-image: url("./../../../../../assets/images/whats-new-bg.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position-y: center;
      background-color: unset;
      span {
        position: absolute;
        left: 1rem;
        z-index: 1;
        color: #fff;
        font-family: Arial;
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        img {
          margin-top: -2px;
        }
      }
    }
  }
}
