.mdm--site-details-container {
  // padding: 1.5rem 1rem 1rem 1.5rem;
  padding-top: 2.5vh;
  // margin-left: 2vh;
  .k-breadcrumb {
    margin-left: -0.5vw;
  }
  .site-update-info-notification {
    .k-icon {
      font-size: 0.75vw;
    }
    .k-notification-content {
      span {
        font-family: Arial;
        font-size: 0.75vw;
      }
    }
  }
  .mdm--site-details {
    // margin-top: 2rem;
    section.mdm-sd-right-section {
      width: 80%;
      padding: 0.25vw 0 0 1.25vw;
      .mdm--sd-details-parent-tab {
        // line-height: 20px;
        height: 100%;
      }
      .notification-message {
        font-family: Arial;
        font-size: 0.75vw;
        margin: 1vw 0 0 0.75vw;
      }
      .k-tabstrip-items-wrapper {
        line-height: 1.125vw;
        .k-item {
          margin: 0 5vw 0 0.5vw;
          border: 0;
          .k-link {
            font-family: Shell Font Heavy;
            font-weight: 600;
            font-size: 0.95vw;
            letter-spacing: 0em;
            text-align: left;
            color: #c4c4c4;
            padding: 0;
          }
          &.k-active {
            .k-link {
              color: #404040;
            }
          }
        }
        // .k-tabstrip-items {
        //   box-shadow: 0 4px 6px -6px rgba(0, 0, 0, 0.15);
        //   border-bottom: 0.25px solid rgba(0, 0, 0, 0.1215686275);
        // }
      }
      .k-content.k-active {
        padding: 0;
        background: transparent;
        border-radius: 0;
        overflow: hidden;
      }
      .mdm--site-detail {
        margin: 0.5vh 0vh 1.9vh 0vh;
        background: #ffffff;
        height: 39.5vh;
        box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 15%);
        overflow: hidden;

        .k-tabstrip-items-wrapper {
          margin: 0 0 0.5vw 0;
          .k-item {
            // border: none;
            margin-right: 0;
          }
          .k-link {
            font-family: Shell Font Bold;
            font-size: 0.8vw;
            line-height: 2vw;
            color: #404040;
            div {
              display: inline-block;
            }
            .tab-error-icon {
              display: inline-block;
              margin-left: 0.25vw;
              color: #d51923;
              .k-i-warning {
                color: #d51923;
                width: 1em !important;
              }
            }
          }
          .k-active {
            border-bottom: 2px solid !important;
            .k-link {
              color: #003c88 !important;
            }
          }
          &.k-active {
            border-bottom-color: #003c88 !important;
            .k-link {
              color: #003c88 !important;
            }
          }
          .k-tabstrip-items {
            justify-content: space-between;
            border-bottom: 2px solid rgba(0, 0, 0, 0.1215686275);
          }
        }
        // .k-content.k-active {
        //   padding-left: 0.5rem;
        //   margin-top: 1rem;
        // }

        .k-active {
          .k-animation-container {
            max-height: 30vh;
            overflow: auto;
          }
        }
        &.mdm--sd-basic-details-top-section {
          height: 42vh;
          box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 15%);
          .k-active {
            .k-animation-container {
              max-height: 33vh;
            }
            box-shadow: none;
          }
        }
        .mdm--sd-details-child-tab {
          margin: 0 1vw;
          .k-tabstrip-items-wrapper {
            .k-item {
              margin-left: 0;
            }
          }
        }
      }
      .mdm-sd-map-container {
        height: 100%;
        margin: 0.5vw;
        .mdm-sd-map {
          height: 100%;
          width: 100%;
          box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.15);
          .iframed-app__iframe {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .mdm--sd-button-container {
      // margin-top: -2.5rem;
      // height: 2rem;
      z-index: 10;
      .k-button {
        height: 1.5vw;
        width: 5vw;
        margin-left: 1vw;
        .k-button-text {
          font-family: Arial;
          font-weight: 700;
          font-size: 0.75vw;
          // line-height: 24px;
        }
      }
      .cancel-btn {
        margin-left: -10vw;
      }
      .edit-btn {
        // margin-left: -5vw;
      }
    }
  }
  .k-tabstrip-items-wrapper {
    border-bottom: none;
    .k-tabstrip-items {
      border: 0;
    }
    .k-item {
      color: #000;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #0000001f;
      .k-link {
        p {
          font-family: Shell Font;
          font-weight: 700;
          letter-spacing: 0vw;
          text-align: left;
          color: #404040;
        }
      }
      &.k-active {
        font-family: Shell Font;
        font-weight: 600;
        color: #003c88;
        border-top: none;
        line-height: 0.875vw;
        background: transparent;
        // border-bottom: 1px solid;
        // border-bottom-color: rgba(0, 0, 0, 0.15) !important;
      }
    }
  }
  .k-content {
    border: none !important;
    .k-animation-container,
    .k-child-animation-container {
      width: 100%;
      height: 100%;
      > div {
        height: 99%;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
      }
    }
  }
  .mdm--no-records {
    margin-left: 0.25vw;
    line-height: 2vw;
    font-family: Arial;
    font-weight: 400;
    font-size: 0.85vw;
  }
  .k-input.mdm--sd-column-value,
  .k-dropdownlist.mdm--sd-column-value {
    height: 3.4vh;
    padding: 0 0 0 0.6vw !important;
  }
  .mdm--sd-button-container {
    position: absolute;
    right: 2vw;
    margin-top: -1.5vh;
    height: 3vh;
    .k-button {
      max-height: 5vh;
      margin-left: 1vw;
      z-index: 5;
      .k-button-text {
        font-family: Arial;
        font-size: 0.85vw;
        line-height: 1.25vw;
        font-weight: 700;
        height: 3vh;
        width: 8vh;
        line-height: 3vh;
      }
    }
  }
  .error-notification {
    width: 50%;
  }
  .tag {
    height: 3vh;
    line-height: 3vh;
    max-width: 8%;
    border-radius: 0.9375rem;
    text-align: center;
    background-color: #c4c4c4;
    &.tag--green {
      background-color: #a7f0ba;
      span {
        color: #044317;
      }
    }
  }
}

.mdm-edit-site-confim-dialog {
  border: none;
  .k-dialog-buttongroup {
    margin: auto;
    display: block;
    border: none;
    .k-button {
      width: 4vw;
    }
  }
  .k-window-content.k-dialog-content {
    p {
      font-family: Arial;
      font-size: 1vw;
      line-height: 1.5vw;
    }
  }
  .k-window-actions {
    margin: 0;
  }
}
