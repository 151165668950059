@import "../../styles/index.scss";

.login-side-panel .mdm--sidepanel .mdm-sidepanel-body {
  height: 100% !important;
  top: 0 !important;
  .k-radio-md {
    width: 1vw;
    height: 2vh;
  }
  .mdm--sidepanel-body-content {
    margin: 1rem 0.5rem;
    font-family: Arial;
    overflow: auto;
    height: 90%;
    font-size: 0.85vw;
    a {
      font-family: Arial;
      overflow: auto;
      height: 90%;
      font-size: 0.85vw;
      color: blue !important;
    }
  }
  .mdm--sidepanel-body-content-item {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #d3d3d3;
  }
}
.login {
  height: 100vh;
  width: 100%;
  position: none;
  color: $white;
  // border: 2px solid red;
}
.container-blur {
  width: 100%;
  height: auto;
  padding-bottom: 7vh;
  background: rgba(86, 133, 201, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-main-container {
  width: 70%;
  height: 100%;
}
.login-container {
  width: 100%;
  padding: 0px 20%;
  margin-top: 7.4vh;
  .k-radio-label {
    color: #ffffff;
    font-size: 2.5vh;
    font-family: $Shell_Font_Book;
  }
}
.header {
  width: 100%;

  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  .logo {
    height: 3.47vw;
    width: 3.54vw;

    position: absolute;
    top: 0vh;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 37.25px;
  }
  h1 {
    margin-top: 8.6vh;
    font-family: $Shell_Font_Bold;
    font-size: 1.7vw;
    font-style: normal;
    font-weight: bold;
    line-height: 49px;
    letter-spacing: 0.125em;
    top: 10rem;
    color: #ffffff;
  }
}

.form {
  width: 100% !important;

  padding: 3vh 0px;
}
.groups {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.group1,
.group2 {
  font-family: $Shell_Font_Book;
  font-size: 1.2vw;
  margin-inline-start: 10px;
  .hint {
    font-family: $Shell_Font_Book;
    font-size: 0.9vw;
    font-style: normal;
    padding-left: 25px;
    padding-bottom: 1vh;
    color: #ffffff;
  }
}
.login-footer {
  .straight-line {
    font-size: 1.7vw;
    font-family: $Shell_Font_Bold;
    font-style: normal;
    font-weight: bold;
    line-height: 2.6vh;
    color: white !important;
    text-decoration: none !important;
  }
  h1 {
    text-align: center;
    align-items: center;
    padding: 0;
  }
  a {
    font-family: $Shell_Font_Bold;
    color: $yellow !important;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8vw;
    line-height: 2.1vh;
    cursor: pointer;
  }
  p {
    line-height: 32px;
    .copy {
      font-family: $Shell_Font_Book;
      padding-right: 40px;
    }
  }
  .login-helpsection {
    .straight-line {
      font-family: $Shell_Font_Bold;
      font-style: normal;
      font-weight: bold;
      font-size: 1.2vw;
      line-height: 1.5vh;
      color: white;
    }
    float: right;

    padding: 0px 20%;
    a {
      font-family: $Shell_Font_Bold;
      font-style: normal;
      font-weight: bold;
      font-size: 1.2vw;
      line-height: 1.5vh;
      cursor: pointer;
      color: #fbce07;
    }
    p {
      line-height: 1.5vh;
    }
  }
}

.mdm-error-message {
  position: relative;
  z-index: 999999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  border: 2px solid red;
  background-color: rgba(255, 255, 255, 0.4);

  // .mdm-error-message-label {
  //   font-size: 1vw;
  // }
}

.login-bg {
  background: url(../../../assets/images/smartcity.png) no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
}
