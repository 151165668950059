.mdm--change-history-small-container {
  padding-top: 3.5vh;
  margin-bottom: 1.5vh;
  height: 13.5vh;

  .mdm--change-history-small-body {
    padding: 2vh 1vw;
    padding-right: 0.25vw;
    height: 100% !important;
  }
  .left-section {
    align-items: center;
    height: 100%;
    .image {
      margin: auto 0.5vw;
      height: 1.25vw;
      width: 1.25vw;
    }
    background-image: url("./../../../../../assets/images/change-history-bg.svg");
    background-position: right bottom; /*Positioning*/
    background-repeat: no-repeat;
    background-size: 1.5vw 4vh;
  }
  .mdm--change-history-text-section {
    height: 100%;
    width: 52%;
    background-color: rgba(230, 245, 248, 1);
  }
  .change-history-text {
    font-family: Arial;
    font-size: 0.75vw;
    color: #0097bb;
  }
  .buttons-container-button {
    margin: auto;
    background: none;
    border: unset;
    font-style: normal;
    font-size: 0.75vw;
    color: #404040;
    flex-direction: row-reverse;
    .k-button-text {
      font-family: Arial;
      font-size: 0.75vw;
    }
  }
  .buttons-container-button::after,
  .buttons-container-button::before {
    box-shadow: none !important;
  }
  .k-i-arrow-chevron-up {
    top: -0.15vh;
  }
}
