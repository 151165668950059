.mdm--sd-rad-table-details {
  justify-content: space-between;
  .mdm--sd-rad-table-detail {
    font-style: normal;
    font-size: 0.75vw;
    // line-height: 32px;
    color: #404040;

    .mdm--sd-rad-table-headers {
      .mdm--table-th {
        padding-left: 0.25vw;
        font-weight: 700;
        font-family: Arial;
        font-size: 0.83vw;
        border-bottom: 0.2vw solid rgba(0, 0, 0, 0.1);
        // line-height: 1.25vw;
      }
      .mdm--table-th:last-child {
        border-bottom: none;
      }
    }
    .mdm--sd-rad-table-body {
      .table--row {
        height: auto;
        margin: 0 0 0.5vh;
        & > .mdm--row {
          height: auto;
        }
        // & > .mdm--row.edit-row {
        //   height: 2.75vw;
        // }
      }
      .k-form-error {
        line-height: 1vw;
        margin-top: 0;
        margin-left: 0.25vw;
      }
      .loading--row {
        .mdm--table-td:last-child {
          visibility: hidden;
        }
      }
      .mdm--table-td {
        // padding-left: 0.8vw;
        font-family: Arial;
        // line-height: 1vw;
        padding: 0.5vh 0.25vw;
        word-break: break-word;
        font-size: 0.83vw;
        .k-skeleton {
          height: 2vw;
          margin-right: 0.5vw;
        }
      }
    }
    .editActionRow {
      display: inline-flex;
      // justify-content: center;
      padding-top: 0.5vh !important;
      margin-left: 0.25vw;
      &.mdm--table-td {
        padding-top: 0 !important;
        background: #ffffff;
        button {
          color: #ffffff;
          background: #003c88;
          border-radius: 50%;
          margin-right: 0.25vw;
          font-size: 1vw;
          &#save-btn,
          &#discard-btn {
            display: none;
          }
        }
      }
    }
    .edit-row {
      .editActionRow {
        align-items: center;
      }
    }
    .mdm--row.editable:hover {
      background-color: #003c88;
      color: #ffffff;
      .k-button {
        visibility: visible;
      }
      .plain-text-field {
        color: #ffffff;
      }
    }
    .mdm--row.editable.edit-row {
      background-color: #003c88;
      color: #ffffff;
      .k-button {
        visibility: visible;
      }
    }
    .edit-field {
      display: none;
    }
    .edit-row {
      .plain-text-field {
        display: none;
      }
      .edit-field {
        display: contents;
      }
      #edit-btn,
      #delete-btn {
        display: none;
      }
      #save-btn,
      #discard-btn {
        display: inline-block !important;
      }
    }
    .k-dropdownlist {
      .k-input-value-text {
        font-size: 0.83vw !important;
      }
    }
  }
  &.facility {
    .mdm--sd-rad-table-detail {
      width: 50%;
      .mdm--table-th,
      .mdm--table-td {
        width: 35%;
      }
      .mdm--table-th:first-child,
      .mdm--table-td:first-child {
        width: 30%;
      }
      .mdm--table-th:last-child,
      .mdm--table-td:last-child {
        margin-left: 0.25vw;
        width: 15%;
      }
      .mdm--table-td {
        &.facilitySubType-changed,
        &.facilityType-changed,
        &.facilitySbtypeValue-changed {
          border-left: 0.25rem solid #6dd569;
          border-radius: 4px;
        }
      }
    }
  }
  &.fuel-products {
    .mdm--sd-rad-table-detail {
      width: 46%;
      .mdm--table-th,
      .mdm--sd-rad-table-body,
      .mdm--table-td {
        width: 100%;
      }
    }
    .mdm--table-td {
      &.fuelProductName-changed {
        border-left: 0.25rem solid #6dd569;
        border-radius: 4px;
      }
    }
  }
  &.opening-hours {
    .mdm--sd-rad-table-detail {
      width: 46%;
      .mdm--table-th,
      .mdm--table-td {
        width: 20%;
      }
      .mdm--table-th:first-child,
      .mdm--table-td:first-child {
        width: 30%;
      }
      .mdm--table-th:last-child,
      .mdm--table-td:last-child {
        margin-left: 0.25vw;
        width: 10%;
      }
    }
    .mdm--table-td {
      &.facilityType-changed,
      &.day-changed,
      &.openingToDate-changed,
      &.openingFromDate-changed {
        border-left: 0.25rem solid #6dd569;
        border-radius: 4px;
      }
    }
  }
  &.ev-custom-field {
    .mdm--sd-rad-table-detail {
      width: 46%;
      .mdm--table-th,
      .mdm--table-td {
        width: 40%;
      }
      .mdm--table-th:last-child,
      .mdm--table-td:last-child {
        margin-left: 0.25vw;
        width: 18%;
      }
    }
    .mdm--table-td {
      &.countryName-changed,
      &.localMarketTypeName-changed,
      &.localMarketTypeValue-changed {
        border-left: 0.25rem solid #6dd569;
        border-radius: 4px;
      }
    }
  }
  &.ev-site-contact {
    .mdm--sd-rad-table-detail {
      width: 46%;
      .mdm--table-th,
      .mdm--table-td {
        width: 13%;
      }

      .mdm--table-th:nth-child(3),
      .mdm--table-td:nth-child(3) {
        width: 10%;
      }
      .mdm--table-th:first-child,
      .mdm--table-td:first-child {
        width: 50%;
      }
      .mdm--table-th:last-child,
      .mdm--table-td:last-child {
        margin-left: 0.25vw;
        width: 15%;
      }
    }
    .mdm--table-td {
      &.employeeType-changed,
      &.employeeFirstName-changed,
      &.employeeMiddleName-changed,
      &.employeeLastName-changed {
        border-left: 0.25rem solid #6dd569;
        border-radius: 4px;
      }
    }
  }
  &.legacy-ids,
  &.external-ids {
    .mdm--sd-rad-table-detail {
      width: 46%;
      .mdm--table-th,
      .mdm--table-td {
        width: 37%;
      }
      .mdm--table-th:last-child,
      .mdm--table-td:last-child {
        margin-left: 0.25vw;
        width: 15%;
      }
    }
    .mdm--table-td {
      &.legacySiteTypeName-changed,
      &.legacySiteId-changed,
      &.externalSiteType-changed,
      &.externalSiteId-changed {
        border-left: 0.25rem solid #6dd569;
        border-radius: 4px;
      }
    }
  }
  &.site-currency {
    .mdm--sd-rad-table-detail {
      width: 40%;
      .mdm--table-th,
      .mdm--table-td {
        width: 100%;
      }
      .mdm--table-th:last-child,
      .mdm--table-td:last-child {
        margin-left: 0.25vw;
        width: 15%;
      }
    }
    .mdm--table-td {
      &.currency-changed {
        border-left: 0.25rem solid #6dd569;
        border-radius: 4px;
      }
    }
  }
  &.hydrogen-mobility {
    .mdm--sd-rad-table-detail {
      width: 100%;
      .mdm--table-th,
      .mdm--table-td {
        width: 18%;
      }
      .mdm--table-th:last-child,
      .mdm--table-td:last-child {
        margin-left: 0.25vw;
        width: 15%;
      }
    }
    .mdm--table-td {
      &.currency-changed {
        border-left: 0.25rem solid #6dd569;
        border-radius: 4px;
      }
    }
  }
  &.ev-charge-post {
    .mdm--sd-rad-table-detail {
      width: 70%;
      .mdm--table-th,
      .mdm--table-td {
        width: 14.25%;
      }
      margin: 0;
      div.plain-text-field {
        height: 100%;
        width: 100%;
      }
      .selectable:hover {
        background-color: #d3d3d3;
        color: #000;
        cursor: pointer;
        .plain-text-field {
          color: #000;
        }
      }
      .selected {
        background-color: #003c88 !important;
        color: #ffffff !important;
        &.selectable:hover .plain-text-field,
        .plain-text-field {
          color: #ffffff;
        }
      }
      .table-heading {
        font-family: Arial;
        font-size: 0.85vw;
        &::before {
          content: "*";
          color: red;
          font-size: 0.95vw;
          display: inline-block;
        }
      }
      &.connector-data {
        width: 30%;
        .mdm--table-th,
        .mdm--table-td {
          width: 35%;
        }
        .table-heading {
          font-family: Arial;
          font-size: 0.85vw;
          font-weight: 600;
          text-align: center;
          width: 80%;
          &::before {
            content: "";
          }
        }
      }
      .mdm--table-th:last-child,
      .mdm--table-td:last-child {
        margin-left: 0.25vw;
      }
    }
  }
  .buttons-container-button {
    color: #ffffff !important;
    background: #003c88 !important;
    margin-right: 0.25vw;
    aspect-ratio: 1;
  }
  .k-input,
  .k-timepicker .k-button,
  .k-dropdownlist,
  .k-dropdownlist .k-button-solid-base,
  .time-picker-table-view,
  .time-picker-table-view button,
  .buttons-container-button {
    height: 3.4vh !important;
  }

  .mdm--sd-rad-table-edit-add-btn {
    .buttons-container-button.k-button {
      height: auto !important;
    }
  }
  .k-button-icon {
    font-size: larger;
  }
  .k-dropdownlist {
    padding-left: 0.2vw;
  }
  .time-picker-table-view {
    padding-left: 0.2vw;
  }
}

.mdm--table-td {
  &.chargepostId-changed,
  &.evChargerBrandCd-changed,
  &.evChargerOperatorCd-changed,
  &.countryCd-changed,
  &.ocpiEvseId-changed,
  &.powerTypeCd-changed,
  &.powerKW-changed,
  &.connectorTypeCd-changed,
  &.externalEvseId-changed {
    border-left: 0.25rem solid #6dd569;
    border-radius: 4px;
  }
}
