.mdm--change-request-histroy.header {
  display: -webkit-inline-box;
  height: 100%;
  width: 100%;

  // flex-direction: row;

  .main-div-details {
    width: 50%;
  height: 88vh;

    box-shadow: 4px 4px 10px 1px #00000026;
    margin: 3vh;
    background-color: #fff;
  }

  .main-div-change{
    width: 45%;
    height: 88vh;
    
      box-shadow: 4px 4px 10px 1px #00000026;
      margin: 3vh;
      background-color: #fff;
   
  }
  .button-div{
  margin-right: 2vh;
  margin-top: 1vh;
}

  .button-main-div{
    display: flex;
    justify-content: space-between;
  }

  .Approve-btn{
    // margin-left: 65vh;
    font-family: Arial;
    font-size: 0.75vw;
    line-height: 1.25vw;
    font-weight: 700;
    height: 3vh;
    width: 8vh;
    background-color: #003c88;
    color: #ffffff;
    border: none;
    padding: 0vh 0vw;
    text-align: center;
  }
  .Approve-btn:disabled,
  .Approve-btn[disabled]{
    background-color: #3e86e1;
  }
  .Rejected-btn{
    margin-left: 2vh;
    font-family: Arial;
    font-size: 0.75vw;
    line-height: 1.25vw;
    font-weight: 700;
    height: 3vh;
    width: 8vh;
    background-color: #dc1627;
    color: #ffffff;
    border: none;

  }
  .Rejected-btn:disabled,
  .Rejected-btn[disabled]{
    background-color: #e16e6e;
  }
  .itemDetail {
    width: 8vh;
    height: 5vh;
    padding: 0.6vh;
    font-family: Shell Font Bold;
    font-size: 0.8vw;
    line-height: 1.6vw;
    background-color: #fff;
  }

  .itemSummary {
    height: 3vh;
    padding-right: 4vh;
    font-size: 2vh;
    text-align: end;
  }

  .colum1 {
    padding-right: 2vh;
    padding-left: 2vh;
    padding-top: 0.5vh;
    font-family: Shell Font Bold;
    font-size: 0.8vw;
    line-height: 1.6vw;
  }

  .colum2 {
    font-size: 1.7vh;
    width: 63vh;
    overflow: hidden;
    // white-space: nowrap; 
    text-overflow: ellipsis;
  }

  .other {
    padding-right: 5vh;
    padding: 3vh;
    font-family: Shell Font Bold;
    font-size: 1vw;
    line-height: 1.6vw;
  }

 
  .change {
    height: 5vh;
    background-color: aliceblue;
    display: flex;
    align-items: center;
    padding-left: 1.5vh;
    font-family: Shell Font Bold;
    font-size: 0.8vw;
    line-height: 1.6vw;
  }

  .heading {
    font-family: Shell Font Bold;
    font-size: 0.8vw;
    line-height: 1.6vw;
  }

  .button {
    background-color: rgb(146, 255, 127);
    font-size: xx-small;
    border-radius: 3.5vh;
    color: white;
    height: 3vh;
    font-weight: bolder;
    border: none;
    width: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .view {
    background-color: whitesmoke;
    font-size: xx-small;
    border-radius: 1.5vh;
    color: black;
    height: 3vh;
    font-weight: bolder;
    border: none;
    width: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .back-button {
    font-size: 24px; /* Adjust the size as needed */
    background: transparent; /* Optional: to remove button background */
    border: none; /* Optional: to remove button border */
    cursor: pointer; /* Optional: to change cursor on hover */
  }
  
  .back-button svg {
    width: 24px; /* Adjust width */
    height: 24px; /* Adjust height */
  }
  
}
