.mdm--site-list-container {
  .mdm--sites-list-body {
    .mdm--site-list-tabs {
      width: 100%;
      .k-tabstrip-items-wrapper {
        border-bottom: none;
        // margin: 0 1rem;
        margin: 0 0.83vw;
        .k-tabstrip-items {
          border: 0;
          // padding-right: 15%;
          padding-right: 10%;
          justify-content: space-between;
          // border-bottom: 2px solid #0000001f;
          border-bottom: 0.1vw solid #0000001f;
        }
        .k-item {
          color: #000;
          border-left: none;
          border-right: none;
          span.k-link {
            padding: 0.2vw 0.4vw;
            padding-left: 0;
            .mdm--lp-sites-list-tab-title {
              font-family: Arial;
              // font-size: 1rem;
              // margin-right: 0.5rem;
              font-size: 0.83vw;
              margin-right: 0.5vw;
            }
            .mdm--dot {
              font-family: Arial;
              font-weight: 600;
              // font-size: 10px;
              // line-height: 2rem;
              // height: 2rem;
              // width: 2rem;
              font-size: 0.55vw;
              line-height: 2vw;
              height: 2vw;
              width: 2vw;
              color: #fff;
              background-color: #adadad;
              border-radius: 50%;
              display: inline-block;
              text-align: center;
            }
          }
          &.k-active {
            font-family: Arial;
            font-weight: 600;
            color: #003c88;
            // .mdm--lp-sites-list-tab-title {
            // border-bottom: 2px solid !important;]
            border-bottom: 0.15vw solid !important;
            border-bottom-color: #003c88 !important;
            // }
            .mdm--lp-sites-list-tab-title {
              color: #003c88 !important;
            }
            .mdm--dot {
              // border-bottom: 2px solid !important;
              // border-bottom-color: #003c88 !important;
              // color: #fff;
              background-color: #003c88 !important;
            }
          }
        }
      }
      .k-content {
        // padding: 0 1rem;
        padding: 0 0.83vw;
        border: none;
        overflow: hidden;
        .k-animation-container,
        .k-child-animation-container,
        .mdm--data-grid,
        div[role="tabpanel"] {
          height: 100%;
          width: 100%;
        }
      }
      // .mdm-grid-cell-empty {
      //   text-align: center;
      // }
      .mdm-grid-custom-td {
        > div {
          display: inline-block;
          float: right;
        }

        .k-icon {
          float: right;
          // background-size: 1rem;
          background-size: 0.83vw;
          background-repeat: no-repeat;
          // top: 0.25rem;
          top: 0.1vw;
          width: 1vw !important;
          height: 1vw;
          &.k-i-check-circle-icon-circle {
            background-image: url("./../../../../assets/images/icons/check-circle-icon.svg");
          }
          &.k-i-warning-icon-circle {
            background-image: url("./../../../../assets/images/icons/warning-filled-icon.svg");
          }
          &.k-i-warning-red-icon-circle {
            background-image: url("./../../../../assets/images/icons/warning-filled-red-icon.svg");
          }
          &.k-i-information-icon-circle {
            background-image: url("./../../../../assets/images/icons/information-filled-icon.svg");
          }
          &.k-i-information-red-icon-circle {
            background-image: url("./../../../../assets/images/icons/information-filled-red-icon.svg");
          }
        }

        &.mdm--action-item-active span {
          color: #2a9041;
        }
        &.mdm--action-item-work_in_process span,
        &.mdm--action-item-invalid span,
        &.mdm--action-item-closed_temporarily span,
        &.mdm--action-item-not_specified span,
        &.mdm--action-item-recycle_bin span {
          color: #eb8705;
        }
        &.mdm--action-item-closed_permanently span,
        &.mdm--action-item-landbank span,
        &.mdm--action-item-completed_not_opened span,
        &.mdm--action-item-non-retail_shell_location span {
          color: #a82424;
        }
      }
    }
  }
  #expandBtn {
    height: 2rem;
  }
  .k-grid-norecords {
    height: 62vh;
  }
}

.mdm--multiselect-dropdown {
  font-size: 1vw !important;
}
