.mdm--change-history-container {
  width: 102%;
  height: 100%;
  // border: 2px solid blue;
  // padding: 0 0 1% 1%;
  // overflow-x: hidden;
  // margin-top: -2%;

  .mdn-change-history-first-row {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    height: 2vw;
    width: 20vw;
    padding-top: 0.5vw;
  }
  .mdm-back-arrow-icon {
    width: 0.9vw;
    height: 1vw;
    cursor: pointer;
  }
  .mdm-change-history-back-arrow {
    margin-right: 0.5vh;
    display: flex;
   }
  .mdm--change-history-container-title {
    font-family: Shell Font Bold;
    font-size: 0.95vw;
    // line-height: 0.83vw;
    margin-left: 0;
    margin-bottom: 1.25vh;
  }
  // table changess:
  .mdm--change-history-container-table-container {
    width: 98%;
    height: 84vh !important;
    padding: 1% 2%;
    background: #ffffff;
    margin: 0.1vw;
    box-shadow: 0px 2px 10px -2px rgb(0 0 0 / 15%);
  }
  .mdm--change-history-container-table-container-first-row {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0vh 0;
    justify-content: center;
    // border: 1px solid black;
  }
  .mdm-main-container-refresh-button {
    align-items: right;
    justify-content: right;
    width: 58%;
    // float: right;
    // height: 100px;
    // border: 1px solid red;
    // width: 100%;
    // height: 3vh;
    // border: 1px solid blue;
    display: flex;
    // align-items: flex-end;
    // justify-self: unset;
    // justify-content: center;
    margin-right: 1.5vw;
  }
  .mdm-refresh-label,
  .mdm-refresh-label-2 {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 0.8vw;
    // line-height: 24px;
    padding: 0.4vw;
    color: #003c88;
  }
  .mdm-change-history-refresh-image {
    height: 1vw;
    width: 1vw;
  }
  .mdm--chg-his-container-colum {
    width: auto;
    height: 6vh;
    // border: 1px solid blue;
    display: flex;
    align-items: center;
    margin-right: 1.5vw;
    // margin-bottom: 14px;

    justify-content: space-between;
  }
  .mdm--change-his-search-and-radio {
    display: flex;
    align-items: center;
    justify-content: left;
    // border: 2px solid red;
    width: 100%;
  }

  .mdm--ch-serch-bar {
    width: 21vw;
    height: 68%;
    padding: 0 5%;
    border-radius: 200px;
    font-size: 1vw;
    border: none;
    outline: none;
    border: 0.15vw solid rgba(0, 0, 0, 0.08);
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    //  boder-color: rgba(187, 188, 188, 0.3);
    display: flex;
    text-align: left;
    justify-content: left;
    line-height: 1.4285714286;
  }
  .mdn--ch-fildset-fram {
    border: 2px solid rgb(175, 176, 175);
    border-radius: 7px;
    height: 100%;
    margin: none;
    width: 8vw;
    padding: 3%;
  }
  .mdm--ch-fs-legand {
    float: none;
    width: auto;
    font-size: 0.85vw;
    color: black;

    padding: 0 2%;
    margin-bottom: 0px;
  }
  .mdm--ch-select-container {
    width: 100%;
    height: 100%;
    border: 1px solid red;
    font-size: 0.85vw;
    border: none;
    background-color: transparent;
    outline: none;
  }
  .mdm-ch-seltct-option-box {
    width: 100%;
    height: 100%;
    font-size: 0.75vw;
    border-radius: 1px;
  }
  .mdm--data-grid-toolbar {
    .k-grid-toolbar {
      padding: 0.5vw 0.25vw;
      background: #fff;
      border: none;
      justify-content: space-between;
      .k-form-field {
        width: 50.5%;
        label[for="mdm--data-grid-toolbar-search"] {
          display: none;
        }
        .k-form-field-wrap {
          position: relative;
          width: 100%;
          input#mdm--data-grid-toolbar-search {
            border-radius: 1vw;
            width: 100%;
          }
          .mdm--grid-search-clear-btn {
            position: absolute;
            border: none;
            display: block;
            width: 1.7vw;
            height: 1.7vw;
            line-height: 1vw;
            font-size: 0.75vw;
            border-radius: 50%;
            top: 0;
            bottom: 0;
            right: 0.4vw;
            margin: auto;
            background: #ddd;
            padding: 0;
            outline: none;
            cursor: pointer;
            transition: 0.1s;
          }
        }
      }
      .mdm--data-grid-button {
        background: transparent;
        border: 0;
        padding: 0.25vw;
        box-shadow: unset;
        .k-button-text {
          font-family: Arial;
          font-size: 0.75vw;
          line-height: 1vw;
        }
        //   img {
        //     margin-top: 4px;
        //     height: 14px;
        //   }
        &.mdm--data-grid-export-button {
          img {
            height: 1.5vw;
            width: 1vw;
          }
        }
      }
      &::before {
        display: none;
      }
    }
  }
  .mdm--data-grid {
    .row {
      display: flex;
      &.button-container {
        button {
          width: 50%;
        }
      }
    }

    .k-grid {
      border: 0;
      height: 92%;
      .k-grid-header {
        background: #efefef;
      }
      table {
        thead {
          tr {
            th {
              font-family: Arial;
              font-weight: 600;
              font-size: 0.85vw;
              // line-height: 1.5rem;
              border-width: 0;
              .k-link {
                padding: .4vw 0.9vw;
              }
            }
          }
        }
        tbody {
          tr {
            background: #fff;
            td,
            span.mdm-grid-td-text {
              font-family: Arial;
              font-size: 0.8vw;
              // line-height: 1.5rem;
              color: #404040;
              border: 0;
              white-space: nowrap;
              text-overflow: ellipsis;
              a {
                color: #0d6efd !important;
                text-decoration: underline !important;
                cursor: pointer !important;
              }
              &.mdm--action-item-CLOSED span {
                color: #2a9041 !important;
              }

              &.mdm--action-item-OPEN span {
                color: #eb8705 !important;
              }
            }

            &:not(.k-detail-row):hover {
              background-color: #ededed;
            }
          }
        }
      }
    }
  }
}
