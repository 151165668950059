.ev-section-container {
  .k-animation-container {
    max-height: 32vh !important;
    height: 32vh !important;
    overflow: hidden !important;
  }
  .mdm--sd-details-child-tab {
    height: 100%;
  }
  .k-child-animation-container {
    max-height: 32vh !important;
    overflow: hidden !important;
    > div[role="tabpanel"] {
      justify-content: space-between;
    }
  }

  .mdm--sd-rad-table-details,
  .mdm--details-view-component {
    overflow: auto;
  }
  // .mdm--details-view-component.mdm--row .mdm--sd-column {
  //   margin-bottom: 0 !important;
  // }

  .ev-notification-text {
    font-size: 0.75vw;
    font-family: Arial;
    b::before {
      content: "*";
      color: red;
      font-size: 0.95vw;
      display: inline-block;
    }
  }
  .hydrogen-mobility-items {
    overflow: auto;
    .hydrogen-mobility-item:not(:last-child) {
      border-bottom: 2px solid #d3d3d3;
    }
  }
}
