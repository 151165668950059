.mdm--status-data-container {
  margin-bottom: 1.5vh;
  height: 18vh;

  .mdm--status-data-body {
    padding: 1.5vh 1vw;
    padding-right: 0.25vw;
    height: 90% !important;
  }
  .loading {
    width: 100%;
    height: 100%;
    .mdm--row {
      justify-content: space-between;
    }
    .k-skeleton {
      height: 3vh;
    }
  }
  .mdm--primary-status-section {
    height: 100%;
    width: 52%;
    background-color: rgba(230, 245, 248, 1);
    background-image: url("./../../../../../assets/images/fuel-station-bg.svg");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 1.5vw 6vh;
    padding-top: 1vh;
    .table {
      padding: 0 0.5vw;
    }
    .total_item_container {
      display: flex;
      span {
        font-size: 0.8vw;
        font-weight: 600;
      }
    }
    span {
      font-family: Arial;
      font-size: 0.75vw;
      color: #0097bb;
      &.value {
        font-weight: 600;
      }
    }
    .image {
      margin: auto 0.5vw;
      height: 1.15vw;
      width: 1.15vw;
    }
  }
  .mdm--secondary-status-section {
    padding-top: 1vh;
    margin-left: 1vw;
    span {
      font-family: Arial;
      font-size: 0.75vw;
      &.value {
        font-weight: 600;
      }
    }
  }
  .table {
    display: table;
    margin-bottom: 3vh;
  }

  .row {
    display: table-row;
  }

  .cell {
    display: table-cell;
    padding: 0;
    &.label,
    &.value {
      padding: 0.2vh 0.25vw;
    }
  }
  .no-records {
    width: 100%;
    text-align: center;
    font-size: 0.75vw;
    font-family: Arial;
  }
}
