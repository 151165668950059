.mdm--leadership-landing-page {
  height: 92vh;
  padding: 0.5vw 2vw;
  background-color: #e5e5e5;
  .main-container {
    justify-content: space-between;
    margin-top: 2vh;
  }
  .mdm-ld-rbla-switch {
    display: grid;
    margin-right: 2vw;
  }
  .left-section {
    width: 72%;
  }
  .right-section {
    width: 25%;
  }
  .first-row {
    margin-bottom: 1vw;
    .status-chart-container {
      width: 100%;
      height: 100%;
      justify-content: space-between;
    }
    .status-chart {
      width: 75%;
    }
    .chart-container {
      height: calc(100% - 3.5vh);
      padding: 1.5vh 0.75vw 0 0.75vw;
      background: #fff;
    }
    .status-bar-chart {
      height: calc(100% - 0.5vh);
    }
  }
  .first-row,
  .second-row {
    justify-content: space-between;
    height: 38vh;
    width: 100%;
  }
  .second-row {
    > div {
      width: 48%;
    }
    .donut-chart {
      background: #fff;
      padding: 0.1vw;
    }
  }
  .mdm--landing-page-item-title {
    font-family: Shell Font Bold;
    font-weight: 700;
    font-size: 0.95vw;
    line-height: 0.83vw;
    margin-left: 0;
    margin-bottom: 1.25vh;
  }
  .dashboard-status-counter-blocks-container {
    width: 21%;
    .box-shadow {
      height: 91%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background: #fff;
    }
    .counter-block-item {
      height: 25%;
      width: 80%;
      margin: auto;
      background: #e6f5f8;
      background-image: url(../../../assets/images/fuel-station-bg.svg);
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: 1.5vw 4vh;
      &.total-sites {
        background-image: linear-gradient(
            rgba(230, 245, 248, 0.8),
            rgba(230, 245, 248, 0.8)
          ),
          url(../../../assets/images/icons/menu-icon.svg);
      }
    }
  }

  .dashboard-site-type-counter-blocks-container {
    margin-top: 5vh;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .counter-block-item {
      width: 45%;
      background: red;
      height: 30%;
      &.shell_in_network_retail_site {
        background: #4b5ffa;
      }
      &.shell_commercial_site {
        background: #4cd17f;
      }
      &.shell_licensed_market_site {
        background: #ac58ff;
      }
      &.shell_card_partner_site {
        background: #f39d12;
      }
      &.competitor_site {
        background: #ff8279;
      }
      .dashbord-counter-value {
        line-height: 2.5vw;
      }
    }
  }
  .dashbord-counter-label {
    color: #0097bb;
    font-size: 0.85vw;
    font-family: Arial;
    padding: 1vh 0.5vw 0;
  }
  .dashbord-counter-value {
    color: #0097bb;
    font-size: 1.5vw;
    font-family: Arial;
    font-weight: 600;
    padding: 0 0.5vw;
  }
  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    height: calc(100% - 3.5vh);
    .loading-doughnut {
      border: 2vw solid rgba(66, 66, 66, 0.2);
      border-radius: 50%;
      border-top: 2vw solid rgba(66, 66, 66, 0.25);
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
      height: 20vh;
      width: 20vh;
      display: block;
    }
    .skeleton-container {
      display: flex;
      width: 35%;
      height: 50%;
      justify-content: space-evenly;
      transform: rotate(180deg);
      .k-skeleton {
        width: 2vw;
        &.rectangle-0,
        &.rectangle-1 {
          height: 10vw;
        }
        &.rectangle-2 {
          height: 6vw;
        }
        &.rectangle-3 {
          height: 8vw;
        }
        &.rectangle-4 {
          height: 5vw;
        }
        &.rectangle-5 {
          height: 4vw;
        }
      }
    }
  }
  .no-records-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    height: calc(100% - 3.5vh);
    .no-records {
      font-size: 0.75vw;
      font-family: Arial;
    }
  }
  .box-shadow {
    box-shadow: 0px 2px 10px -2px rgb(0 0 0 / 15%);
    // border: 1px solid #595959;
    // border-radius: 0.35vw;
  }
  .mdm--ld-multiselect-dropdown {
    border-color: #595959;
    input {
      font-family: Arial;
      font-size: 0.8vw;
      margin-left: 0.25vw !important;
    }
    .k-chip-label {
      font-family: Arial;
      font-size: 0.6vw;
      font-weight: 700;
    }
  }
  .mdm--ld-multiselect-dropdown::after {
    content: url(../../../assets/images/icons/down-arrow.svg);
    position: relative;
    right: 0.5vw;
    top: -0.25vh;
    margin: auto;
  }
  .mdm--ld-button-label {
    font-family: Arial;
    font-weight: 700;
    font-size: 0.9vw;
    line-height: 1vw;
    margin: 0;
  }

  .k-switch {
    height: 3vh;
    outline: 1.7px solid rgba(89, 89, 89, 0.35);
    margin-top: 0.3vh;
  }

  .k-switch-label-on {
    color: #fff;
    font-family: Arial;
  }
  .k-switch-label-off {
    color: #000;
    font-family: Arial;
  }
  .k-switch-on {
    .k-switch-label-off {
      display: none;
    }
  }
  .k-switch-track,
  .k-switch-thumb {
    height: 3vh;
  }
  .k-switch-on .k-switch-track {
    border-color: #2a9041;
    color: white;
    background-color: #2a9041;
    outline: 2px solid #2a9041;
  }
  .k-switch-on:focus .k-switch-track {
    outline: 2px solid #2a9041;
  }
  // .k-multiselect {
  //   border-color: #595959;
  // }
  .doughnut_container-item.item-1 {
    top: -2.1vw;
  }
  .doughnut_container-item.item-2 {
    top: -4.25vw;
  }
  .doughnut_container-item.item-3 {
    top: -6.35vw;
  }
  .doughnut_container-item.item-4 {
    top: -8.5vw;
  }
  .doughnut_container-item.item-5 {
    top: -10.65vw;
  }
  .start_line {
    width: 12.95%;
  }
  .end_line {
    top: -11.5vw;
    width: 50.1%;
  }
  // .doughnut_container {
  //   padding-top: 0;
  // }
  // .mdm--category-graph-container {
  //   .mdm--category-graph-body {
  //     height: 87% !important;
  //   }
  // }
  .mdm--leadership-country-multiselect-tree {
    .k-chip-label {
      font-size: 0.8vw;
      font-family: Arial;
    }
  }
  // .mdm--dashboard-toggle-container,
  // .mdm-ld-small-banner {
  //   height: 16vh;
  //   .mdm--dashboard-toggle-body {
  //     background-image: url(../../../assets/images/dashboard-small-banner.png);
  //     background-size: 100% 16vh;
  //     background-repeat: no-repeat;
  //   }
  // }
  // .mdm-ld-small-banner {
  //   margin-top: 3.5vh;
  //   background-image: url(../../../assets/images/dashboard-small-banner.png);
  //   background-size: 100% 16vh;
  //   background-repeat: no-repeat;
  // }

  .mdm--dashboard-toggle-container {
    .dashboard-toggle-text {
      font-size: 0.9vw;
      padding-left: 0.5vw;
    }
    .buttons-container-button .k-button-text {
      font-size: 0.85vw;
    }
  }
}
.k-animation-container {
  .k-child-animation-container {
    padding-bottom: 1vh;
  }
  .k-treeview {
    padding: 0 0.75vw;
    .k-checkbox {
      border-color: rgba(0, 0, 0, 0.5);
    }
    .k-treeview-toggle {
      margin-left: -6px;
    }
    span.k-treeview-leaf-text {
      font-size: 0.85vw;
      font-family: Arial;
    }
    .k-treeview-group {
      .k-treeview-group {
        max-height: 50vh;
        overflow-y: auto;
        .k-treeview-group {
          max-height: unset;
          overflow-y: hidden;
        }
      }
    }
  }
  .k-searchbox {
    .k-input-inner {
      padding-left: 1vw !important;
    }
    .k-input-icon {
      &::before {
        padding-left: 1vw;
      }
    }
  }
}
