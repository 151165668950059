// .mdm-actions-workflow-header-container {
.mdn-row {
  .full-screen-button {
    cursor: pointer;
  }
  position: sticky;
  top: 0;
  position: -webkit-sticky;
  // border: 1px solid red;
  display: flex;
  justify-content: right;
  justify-items: right;
}
// }

.mdm--action-items-container {
  display: flex;
  flex-direction: column;
  height: 30vh;

  .mdm--data-grid-button {
    background: transparent;
    border: 0;
    padding: 0.25rem;
    box-shadow: unset;
    .k-button-text {
      font-family: Arial;
      font-size: 1vw;
      line-height: 20px;
    }
    //   img {
    //     margin-top: 4px;
    //     height: 14px;
    //   }
    &.mdm--data-grid-export-button {
      img {
        height: 1.5rem;
        width: 1rem;
      }
    }
  }
}

.mdm--action-height-normal {
  overflow: auto;
  padding: 0.5rem 1rem;
  height: 99%;
}

.mdm--action-items-body {
  height: calc(100vh - 27.9rem);
  overflow: hidden;
  margin-top: 0.5rem !important;

  .mdm--action-item {
    margin: 0.75rem;
    height: 8rem;

    border-bottom: 1px solid #0000001f;
    .mdm--action-item-content {
      border-left: 4px solid #eb8705;
      height: 7rem;
      display: flex;
      padding-left: 1rem;
      .mdm--action-item-title-label {
        font-family: Arial;
        font-style: normal;
        font-size: 0.8vw;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.6);
      }
      .mdm--action-item-title-value {
        color: #000000;
        font-size: 0.75vw;
        font-weight: 700;
        margin-top: 0.2rem;
      }
      .mdm--action-item-title-remaining-days {
        font-family: Arial;
        font-style: normal;
        font-size: 0.75vw;
        line-height: 20px;
        color: #c21f1f;
      }
      .mdm--action-item-details {
        flex-basis: 50%;
        .mdm--action-item-title {
          font-family: Arial;
          font-style: normal;
          font-size: 0.75vw;
          line-height: 20px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.87);
        }
        .mdm--action-item-desc {
          font-family: Arial;
          font-style: normal;
          font-size: 0.75vw;
          margin-top: 0.2rem;
          line-height: 20px;
          color: #000000;
        }

        .mdm--action-item-status {
          margin-top: 0.625rem;
          margin-left: 0.3rem;
          font-size: 1vw;
          line-height: 0.15rem;
          font-family: Arial;
          font-weight: 700;
          color: #003c88;
          span {
            margin-left: -0.8rem;
          }
        }
      }
      .mdm--action-item-creation-date {
        flex-basis: 25%;
        margin-top: 0rem;
        font-size: 1.03vw;
      }
      .mdm--action-item-due {
        flex-basis: 25%;
      }
      &.mdm--action-item-open {
        border-left: 4px solid #eb8705;
        .mdm--action-item-status {
          font-size: 1vw;
          line-height: 0.15rem;
          color: #eb8705;
        }
      }
      &.mdm--action-item-planned {
        border-left: 4px solid #003c88;
        .mdm--action-item-status {
          font-size: 1vw;
          line-height: 0.15rem;
          color: #003c88;
        }
      }
      &.mdm--action-item-closed {
        border-left: 4px solid #288a3d;
        .mdm--action-item-status {
          font-size: 1vw;
          line-height: 0.15rem;
          color: #288a3d;
        }
      }
    }
  }
}
