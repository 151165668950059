.mdm--appbar-container {
  position: sticky !important;
  z-index: 5;
  height: 8vh;
  color: #ffffff !important;
  background-image: url("./../../../assets/images/top-app-bar.png");
  background-repeat: no-repeat;
  background-size: 100% 8vh;
  padding: 0 !important;
  justify-content: space-between;
  // .mdm-clock-loader {
  //   width: 6vh !important;
  //   height: 6vh !important;
  // }
  .mdm--app-bar-left-section {
    padding-left: 0.1vw;
  }
  .k-badge-solid.k-badge-primary {
    font-size: 0.5vw;
  }
  .mdm-appbar-icon {
    margin: auto;
    width: 3.9vw;
    padding: 0;
    img {
      min-width: 4vh !important;
      max-width: 4vh !important;
      min-height: 4vh !important;
      max-height: 4vh !important;
    }
  }

  .k-badge-container {
    vertical-align: bottom;
  }
  .mdm-appbar-icon,
  .mdm--appbar-dropdown {
    background: transparent;
    margin-right: 0.3vw;
    border: none;
    color: #ffffff;
    height: 8vh;
    &:hover {
      background-color: #0091b5 !important;
    }
    &:focus {
      box-shadow: none;
    }
    &.mdm--appbar-country-dropdown {
      box-shadow: 0 0 0 2px rgb(0 0 0 / 8%);
      .k-button {
        height: 4vh;
      }
      .k-input-inner {
        margin-left: 1vw;
        text-align: center;
      }
    }
  }
  .k-avatar-image,
  .k-avatar-solid-primary {
    background-color: transparent;
    height: 1.4vw;
    width: 1.4vw;
    overflow: visible;
  }
  #avatar-btn {
    padding: 0;
    margin: 0 2vw 0 1vw;
    &:hover {
      background-color: #0091b5 !important;
    }
  }
  h1.title {
    font-family: Shell Font Heavy;
    font-size: 2.5vh;
    // line-height: 3.5vh;
    color: #ffffff;
    vertical-align: top;
    margin: 1vh 1vh 1vh -0;
  }
  .k-input-value-text {
    font-family: Shell Font Medium;
    font-size: 1.6vh;
    color: #ffffff;
  }

  .k-badge-container {
    margin-right: 0vh;
    cursor: pointer;
  }

  ul {
    font-size: 0.75vw;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  li:hover {
    cursor: pointer;
    color: #84cef1;
  }
  .k-appbar-section {
    display: flex;
    justify-content: center;
    height: 8vh;
    // margin-right: 1vw;
  }
  .k-button k-button-md k-rounded-md k-button-solid k-button-solid-base {
    padding: 0 8px;
  }
  .content li {
    margin: 0;
    padding: 0.5vw 0.5vw;
  }
  .content ul {
    display: block;
  }
  .content {
    padding: 0.4vw 0.5vw;
  }
}

.mdm--popup-options-container {
  left: 94.7vw !important;
  ul {
    font-size: 0.75vw;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  li:hover {
    cursor: pointer;
    color: #84cef1;
  }
  .k-appbar-section {
    display: flex;
  }
  .k-button k-button-md k-rounded-md k-button-solid k-button-solid-base {
    padding: 0 8px;
    font-size: 1vh;
  }
  .content li {
    margin: 0;
    padding: 0.5vw 0.5vw;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  .content ul {
    display: block;
  }
  .content {
    padding: 0;
    span {
      padding: 0 0.5vw;
    }
  }
  .k-icon-button {
    font-size: 1vh;
  }
  .k-icon-button.k-button-md > .k-button-icon {
    font-size: 1vh;
  }
}
.k-i-notification {
  font-size: 1.5vw;
  color: #ffffff;
  margin-top: 0vh;
  margin-left: 0vh;
  height: 1.4vw !important;
  width: 3vh !important;
  &:hover {
    background-color: #0091b5;
    cursor: pointer;
  }
}
.border {
  position: absolute;
  width: 30%;
  top: 4vh;
  height: 0.4vh;
  background-color: aqua;
  bottom: 0;
  left: -50%;
  /* opacity: 0; */
  animation: ani 1.2s linear infinite;
  transition: all 0.8s;
}
@keyframes ani {
  0% {
    left: 1%;
  }
  100% {
    left: 70%;
  }
}
.k-i-arrow-s {
  font-size: 1.2vw;
  width: 3vh !important;
  height: 5vh !important;
}
.k-input-inner {
  padding: 0 !important;
}
.k-input-button {
  height: 8vh;
  width: 3vh;
  padding: 0vh 1vh !important ;
}
.k-button-md {
  padding: 0vh;
}
.mdm--appbar-dropdown .k-input-inner {
  margin: 0vh 0vh 0vh 4vh;
}
.sidepanel-create-site-name {
  color: #0d6efd;
}
