.k-widget.k-window.k-dialog {
  border-bottom: 5px solid #0091b5 !important;
  max-height: 82vh;
}

.k-dialog-buttongroup {
  border-width: 0 0 0 !important;
}
.mdm-main-container {
  /* position:absolute; */
  /* width: 900px;
    height: 550px;
    left: 556px;
    top:265px; */
  background: #fff;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.15);
}
.mdm-main-container img {
  /* position: absolute; */
  /* width: 899px;
      height: 173px;
      left:557px;
      top:265px; */
  background: rgba(0, 60, 136, 0.4);
}
.mdm-profile-container {
  margin-top: 0;
  align-items: center;
  display: flex;
}

.mdm-article-container {
  display: flex;
  flex-wrap: wrap;
}

.mdm-article {
  flex-grow: 1;
  position: relative;
  // border: 2px solid red;
  flex-basis: 30%;
  padding: 1%;
}
.mdm-article h5 {
  font-style: normal;
  font-size: 1.3vw;
  margin-top: 22%;
  text-align: center;
  font-weight: 500;
  /* line-height: 20px; */
  color: #595959;
}
.mdm-user-detail-article {
  flex-basis: 70%;
  font-size: 0.9vw;
  margin-top: 20px;
}
.mdm-user-detail-heading {
  font-size: 1.3vw;
}
.mdm-article:after {
  content: "";
  flex: auto;
}
/* .profile-name{
    display: contents;
    
     margin-left: 50px;
  } */
.mdm-profile-background {
  //   border: 1px solid red;
  //   background-image: url("../../../assets/images/Rectangle/Rectangle 218.svg");

  // background-color: #009eb4;
  opacity: 0.7;
  transform: scale(1.1);
  height: 27vh;
}
.mdm-profile-image-background {
  // border: 2px solid blue;
  overflow: hidden;
  position: relative;
  background-color: rgba(0, 60, 136);
}
.mdm-close-icon {
  cursor: pointer;
  position: absolute;
  z-index: 999;
  height: 22.303895950317383px;
  width: 22.303897857666016px;
  right: 2.8480224609375%;
  top: 10.84765625%;
  border-radius: 0px;
}

.mdm-avatar {
  vertical-align: middle;
  width: 14vh;
  height: 14vh;
  background-color: lightgrey;
  border-radius: 50%;
  margin-top: -8vh;
  position: absolute;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
}
.mdm-btn-logout-color {
  //   background-color: blue !important;
  color: #fff !important;
  padding: 4px 23px !important;
  position: absolute;
  right: 5%;
  background: #003c88 !important;

  span {
    color: #fff !important;
    font-size: 1vw;
  }
}
.mdm-profile-table-title {
  font-style: normal;
  font-weight: 700;
  color: #595959;
  font-size: 2vh;
}
