@import "./typography.scss";
@import "./colors.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background: #e5e5e5;
}
a {
  text-decoration: none;
}
