.mdm--details-view-component {
  &.mdm--row {
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 1vw 0.5vw 0;
    .mdm--sd-column {
      width: 20vw;
      margin-bottom: 0.5vh;
      font-family: Arial;
      font-style: normal;
      font-weight: 400;
      font-size: 0.9vw;
      // line-height: 32px;
      color: #404040;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2vh;
      > div {
        display: contents;
      }
      .mdm--sd-column-label {
        width: 27%;
        font-family: Arial;
        word-break: break-word;
        line-height: 1vw;
        padding: 0.5vw 0;
        // padding-right: 0.4vw;
        font-weight: 700;
        font-size: 0.75vw;
      }
      .mdm--sd-column-label-value-seperator {
        line-height: 1.75vw;
      }

      .k-skeleton-text {
        width: 60% !important;
        margin-right: 0 !important;
        align-self: baseline;
      }
      .k-form-field-wrap {
        width: 60%;
        // min-height: 4vw;
        height: auto;
        .k-dropdownlist .k-button-icon {
          font-size: larger;
        }
        .mdm--sd-column-value {
          width: 100%;
          background: #ebebeb;
          &.k-dropdownlist {
            .k-button {
              height: auto;
            }
          }
        }
      }

      .k-datepicker {
        .k-button {
          height: auto;
        }
      }
      .mdm--sd-column-value {
        width: 60%;
        font-family: Arial;
        word-break: break-word;
        line-height: 1vw;
        padding: 0.5vw 0;
        font-size: 0.85vw;
        .k-input-value-text {
          line-height: 1.5vw;
        }
      }
    }
  }
  &.network-info,
  &.additional-info,
  &.customer-mission,
  &.retailer-data,
  &.space-utilisation {
    .mdm--sd-column {
      .mdm--sd-column-label {
        width: 30%;
      }
      .mdm--sd-column-value {
        width: 60%;
      }
    }
  }
  &.retailer-data.gsap:not(:last-child) {
    margin-top: 0;
    border-bottom: 1px solid #d3d3d3;
  }

  .k-form-error {
    line-height: 1vw;
    margin-top: 0;
    font-family: Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 0.85vw;
  }
  .mdm--sd-column-label-required::after {
    content: "*";
    margin-left: 0.2vh;
    display: contents;
    color: red;
  }
}
