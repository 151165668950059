.mdm--data-grid-toolbar {
  .k-grid-toolbar {
    // padding: 0.5rem 0.25rem;
    padding: 1vh 0.75vw 1vh 0.2vw;
    background: #fff;
    border: none;
    justify-content: space-between;
    .k-form-field {
      width: 50.5%;
      label[for="mdm--data-grid-toolbar-search"] {
        display: none;
      }
      .k-form-field-wrap {
        position: relative;
        width: 80%;
        input#mdm--data-grid-toolbar-search {
          border-radius: 1rem;
          width: 100%;
        }
        .mdm--grid-search-clear-btn {
          position: absolute;
          border: none;
          display: block;
          width: 24px;
          height: 24px;
          // line-height: 16px;
          font-size: 0.75vw;
          border-radius: 50%;
          top: 0;
          bottom: 0;
          right: 5px;
          margin: auto;
          background: #ddd;
          padding: 0;
          outline: none;
          cursor: pointer;
          transition: 0.1s;
        }
      }
    }
    .mdm--data-grid-button {
      background: transparent;
      border: 0;
      padding: 0.25vw;
      box-shadow: unset;
      .k-button-text {
        font-family: Arial;
        // font-size: 12px;
        font-size: 0.75vw;
        // line-height: 20px;
      }
      //   img {
      //     margin-top: 4px;
      //     height: 14px;
      //   }
      &.mdm--data-grid-button {
        img {
          height: 1.25vw;
          width: 1.25vw;
        }
      }
      &.mdm--data-grid-export-button {
        img {
          // height: 1.5rem;
          // width: 1rem;
          height: 1vw;
          width: 0.83vw;
        }
      }
    }
    &::before {
      display: none;
    }
    .k-input-md,
    .k-picker-md {
      font-size: 1vw;
      line-height: 1.428;
    }
  }
}
