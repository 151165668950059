section.mdm--sd-site-basic-details-section {
  width: 22%;
  min-width: 20vw;
  // margin-top: -1.5vw;
  .mdm--sd-site-name-title {
    font-family: Shell Font Bold;
    letter-spacing: 0em;
    text-align: left;
    color: #404040;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.95vw;
    line-height: 1.125vw;
    margin-bottom: 0.5vw;
  }
  input.mdm--sd-site-name-title {
    margin-top: -2vw;
    height: 2vw;
  }
  .mdm--sd-site-basic-details {
    width: 100%;
    height: 82.5vh;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
    overflow: auto;
    background: #ffffff;
    .mdm--sd-sbd-content {
      padding: 0.25vw 0.75vw;
    }
    .mdm--row {
      justify-content: flex-start;
      flex-direction: row;
      font-family: Arial;
      &.mdm--sd-column {
        margin: 0;
        min-height: 2.5vw;
        height: auto;
        padding: 0.25vw 0;
        justify-content: space-between;
        > div {
          display: contents;
        }
        .k-input-value-text {
          line-height: 1.5vw;
        }
        .k-dropdownlist .k-button-icon {
          font-size: larger;
        }
        &.mdm--sd-bsd-status-tag {
          input {
            width: 100%;
          }
          .mdm--sd-bsd-status-tag-label {
            line-height: 1vw !important;
          }
          .mdm--sd-column-value {
            padding-top: 2px;
            height: 3.4vh;
            display: block;
            &.k-dropdownlist {
              width: 100%;
              display: flex;
              height: 3.4vh;
            }
            &.mdm--sd-bsd-status-tag {
              padding: 0.4vw;
              display: inline-block;
              height: 1.75vw;
              text-align: center;
              &.active {
                background-color: #2a9041;
                color: #fff;
              }
              &.closed_permanently,
              &.completed_not_opened,
              &.landbank,
              &.non-retail_shell_location {
                background-color: #a82424;
                color: #fff;
              }
              &.closed_temporarily,
              &.invalid,
              &.not_specified,
              &.recycle_bin,
              &.work_in_process {
                background-color: #eb8705;
                color: #fff;
              }
            }
          }
        }
      }
      .mdm--sd-column-label {
        width: 42%;
        // line-height: 3.4vh;
        font-family: Arial;
        font-weight: 700;
        font-size: 0.75vw;
        word-break: break-word;
        color: #404040;
        display: inline-flex;
        align-items: center;
      }
      .mdm--sd-column-label-value-seperator {
        display: inline-flex;
        align-items: center;
        font-size: 2vh;
      }
      .k-skeleton-text {
        width: 50% !important;
        margin-right: 0 !important;
      }
      .k-form-field-wrap {
        width: 50%;
        display: inline-grid;
        align-items: center;
        .mdm--sd-column-value {
          width: 100%;
          height: 3.4vh;
          background: #ebebeb;
        }
      }
      .mdm--sd-column-value {
        width: 50%;
        line-height: 2vh;
        color: rgba(0, 0, 0, 0.8);
        font-family: Arial;
        font-style: normal;
        font-weight: 400;
        font-size: 0.85vw;
        word-break: break-word;
        vertical-align: super;
        display: inline-flex;
        align-items: center;
        .short-name {
          margin: auto;
          line-height: 1vw;
        }
        &.mdm--sd-bsd-site-manager {
          padding-bottom: 0;
        }
      }
      &.mdm--sd-column:first-child {
        margin-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .basic-site-details-loader {
    width: 20%;
    left: 6%;
  }
  .mdm--sd-bsd-site-name {
    .mdm--sd-column-label {
      display: none !important;
    }
    .mdm--sd-column-value,
    .k-form-field-wrap {
      margin: 0 !important;
      width: 100% !important;
    }
  }
  .k-i-calendar {
    font-size: 2vh;
  }
}
