.mdm--configration-small-container {
  padding-top: 3.5vh;
  margin-bottom: 1.5vh;
  height: 13.5vh;

  .mdm--configration-small-body {
    padding: 2vh 1vw;
    padding-right: 0.25vw;
    height: 100% !important;
  }
 
  .mdm--configration-text-section {
    height: 20vh;
    width: 24vw;
    background-color: rgba(230, 245, 248, 1);
  }
  .configration-text {
    font-family: Arial;
    font-size: 0.75vw;
    color: #0097bb;
  }
  .buttons-container-button {
    margin: auto;
    background: none;
    border: unset;
    font-style: normal;
    font-size: 0.75vw;
    color: #404040;
    flex-direction: row-reverse;
    .k-button-text {
      font-family: Arial;
      font-size: 0.75vw;
    }
  }
  .mdn-configration-first-row {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 2px 5px;
    // border: 2px solid red
  }
}
.configration-title {
  margin-left: 2vh;
  margin-bottom: 1vh;
  font-size: 3.3vh;
}
.configruation-card {
  width: 50%;
  margin: 1vh 0vh 0vh 2vh;
  height: 85vh;
}
.configuration-checkbox {
  font-size: 1.8vh;
}
.k-checkbox:checked {
  border-color: #003c88 !important;
  color: white !important;
  background-color: #003c88 !important;
}
.change-request-notifification div {
  height: 3vh !important;
  width: 21vh !important;
  min-width: 0 !important;
}
.configuration-checkbox input {
  border-color: rgb(0 0 0 / 31%);
  margin-right: 1vh;
}
.small-checkbox-for-selection{
  width: 11vh;
  height: 3vh;
  font-size: 1.4vh;
  margin-right: 8px;
}