.mdm--kpi-graph-container {
  padding-top: 1vh;
  margin-bottom: 1.5vh;
  height: 28.5vh;
  .mdm--landing-page-item {
    padding-left: 2.25%;
    .mdm--landing-page-item-title {
      font-family: Shell Font Bold;
      font-size: 0.95vw;
      // line-height: 10px;
      margin-bottom: 1rem;
    }
  }
  .mdm--landing-page-item-body {
    box-shadow: 4px 4px 10px 1px #00000026;
  }
  .mdm--kpi-graph-body {
    padding: 0.9vw;
    height: calc(100% - 0.5rem) !important;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    .mdm--row {
      // height: 100%;
      // height: 21vh;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      // border-left: 5px solid #dd1d21;
      .mdm--kpi-graph-dropdown {
        margin: auto;
        z-index: 1;
        font-family: Arial;
        font-style: normal;
        font-weight: 400;
        font-size: 1vw;
        line-height: 20px;
        /* identical to box height, or 125% */

        color: #595959;
        #mdm--kpi-graph-dropdown-item {
          background: #fff;
          border: 0;
          box-shadow: none;
        }
      }
      .k-chart-donut-center {
        span {
          font-size: 0.875rem;
          font-family: Arial;
          font-style: normal;
          font-weight: 700;

          line-height: 32px;

          color: #404040;
        }
      }
      .k-chart,
      .k-sparkline,
      .k-stockchart {
        font-size: 1vw;
      }
    }
  }
}
