.mdm--drawer-container {
  height: 92vh;
  background-color: #e5e5e5;
  .k-drawer-container {
    height: 100%;
    .k-drawer {
      background-color: #2a3037;
      color: #ffffff;
      flex-basis: 4vw !important;
      z-index: 3;
      .k-drawer-wrapper {
        width: 100% !important;
        .mdm--drawer-item {
          margin: 0;
          padding: 0;
          height: 7vh;
          .item-descr-wrap {
            margin-left: 1vw;
            display: flex;
            width: 70%;
          }
          .item-descr-text {
            display: none;
            font-family: Shell Font Heavy;
            font-size: 1.75vh;
            // line-height: 2vh;
            color: #ffffff;
            margin: auto 0;
            height: 2.6vh;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
          }
          &:hover {
            background-color: #204266;
          }
          &.k-selected {
            background-color: #4178b1;
          }
        }
      }
      .mdm-drawer-bottom-drawer {
        position: absolute;
        width: 100%;
        &.my-applications {
          bottom: 0;
        }
        &.change-history {
          bottom: 7vh;
        }
        &.notification {
          bottom: 14vh;
        }
      }
      .k-icon {
        margin: 0;
        margin-left: 1.4vw;
        img {
          min-width: 3vh !important;
          max-width: 3vh !important;
          min-height: 3vh !important;
          max-height: 3vh !important;
        }
      }
    }
    &.k-drawer-expanded {
      display: flex;
      .item-descr-text {
        display: block !important;
      }
      .k-drawer {
        flex-basis: 18vw !important;
      }
    }
    .k-drawer-content {
      padding-left: 6vw;
      padding-right: 2vw;
      width: 100%;
      height: 92vh;
      position: absolute;
      overflow: hidden;
      > div {
        height: 100%;
      }
    }
  }
}
