.mdm--recent-updates-container {
  height: 90vh;
  .mdm-change-no-records {
    font-size: 0.85vw;
  }
  .mdm--recent-updates-body {
    position: relative;
    overflow: hidden;
    height: 84.5vh;
    .mdm-row {
      display: flex;
      align-items: center;
      padding: 1.5% 0;
      margin-left: 1vw;
      // margin-top: 0.85rem;
      margin-right: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      .mdm--recent-updates-header {
        // position: absolute;
        font-family: Arial;
        font-style: normal;
        font-weight: 400;
        font-size: 0.85vw;
        // line-height: 16px;
        color: #404040;
        margin-top: 1.5%;
      }
      .mdm--recent-updates-comment-icon {
        img {
          height: 2vw;
          width: 2vw;
        }
      }
      .mdm--recent-updates-close-icon {
        position: absolute;
        right: 7%;
      }

      .mdm--view-all-changes {
        cursor: pointer;
        // position: absolute;
        // right: 3%;
        justify-content: right;
        margin-left: auto;
        img {
          height: 1.1vw;
          width: 1.1vw;
        }
      }
    }
    .k-tabstrip-items-wrapper {
      width: 100%;
      border-bottom: none;
      margin-left: 1vw;
      .k-tabstrip-items {
        border: 0;
      }
      .k-item {
        color: #000;
        border: none;
        // border: 2px solid green;
        // border-bottom: 2px solid transparent;
        width: 50%;
        // height: 30px;
        overflow: hidden;
        height: 100%;
        span.k-link {
          cursor: pointer;
          // padding-top: 1.5rem;
          padding-left: 0;
          .mdm--lp-sites-list-tab-title {
            font-family: Arial;
            font-weight: 700;
            font-size: 0.75vw;
            // line-height: 24px;

            // margin-right: 0.5rem;
            // color: #003c88;
            // border-bottom: 2px solid;
            // border-bottom-color: #003c88;
            padding-bottom: 1px;
          }
          .mdm--lp-sites-list-tab-title-active {
            font-family: Arial;
            font-weight: 700;
            font-size: 0.85vw;
            // line-height: 24px;
            // margin-right: 0.5rem;
            color: #003c88;
            border-bottom: 2px solid;
            border-bottom-color: #003c88;
            padding-bottom: 1px;
          }
        }
        // &.k-active {
        //   font-family: Shell Font;
        //   font-weight: 600;
        //   color: #003c88;
        //   // border-bottom: 2px solid;
        //   // border-bottom-color: #003c88;
        //   .mdm--dot {
        //     color: #fff;
        //     background-color: #003c88 !important;
        //   }
        // }
      }
    }
    .mdm--ul-list-change-history {
      margin: 0;
      outline: none;
      padding: 0;
    }

    .mdm--change-history-tabstrip {
      .mdm--change-history-section {
        width: 100%;
        // height: 100%;
        //  border: 2px solid orangered;
        // overflow-y: auto;
        .k-tabstrip-items-wrapper {
          border-color: transparent;
        }
        .k-animation-container-relative {
          display: block;
        }
      }

      .mdm--ul-list-change-history {
        // height: 100%;
        margin: 0;
        height: 75vh;
        // height: calc(90vh - 30.45rem) !important;
        // border: 3px solid blue;
        outline: none;
        padding: 0;
        // overflow-y: su;
        .loading {
          .k-skeleton {
            margin-right: 1rem;
          }
        }
      }

      .k-tabstrip-items-wrapper.k-hstack {
        // border: 2px solid orchid;
        display: flex;

        height: auto;
        border-bottom: none;
        // margin-left: 1rem;

        // margin-bottom: 10px;
      }

      // .k-item.k-first {
      //   color: #003c88 !important;
      //   border-bottom: 2px solid;
      //   border-bottom-color: #003c88;
      //   font-family: Arial;
      //   font-style: normal;
      //   font-weight: 700;
      //   font-size: 12px;
      //   line-height: 20px;
      //   // border: 2px solid yellow;
      //   width: 50%;
      //   float: left;
      // }
      .k-content {
        padding: 0.2vw 1vw;
        border: none;
      }
      ul {
        // height: 82%;
        overflow-y: auto;
        padding-left: 0;
        overflow: auto;
        overflow-x: visible;
        // border: 3px solid red;

        &::-webkit-scrollbar {
          background-color: blue;
        }

        .mdm--recent-updates-item {
          // border: 1px solid red;
          padding: 0rem 0.25rem 0rem 0rem;
          // margin-left: 0rem;
          margin-top: 0.8rem;
          // margin-right: 1.25rem;
          margin-bottom: 1rem;
          list-style-type: none;
          position: relative;

          .mdm--row {
            // border: 1px solid pink;
            margin-top: 0.2vw;
            .mdm--recent-updates-item-details {
              flex-basis: 55%;

              .mdm--recent-updates-item-title {
                font-family: Arial;
                font-weight: 600;
                font-size: 0.78vw;
                // line-height: 20px;
                // color: rgba(0, 0, 0, 0.87) !important;
                // border: 1px solid green;
                cursor: pointer;
              }
              .mdm--recent-updates-item-title-Ankertag {
                // pointer-events: none;
                // font-weight: 600;
                // // font-size: 14px;
                // font-style: normal;
                // // line-height: 20px;
                // font-size: 0.78vw;
                // text-decoration: none !important;
                // color: black !important;
                font-family: Arial;
                font-weight: 600;
                font-size: 0.75vw;
                border-width: 0;
              }
              .mdm--recent-updates-item-time {
                font-family: Arial;
                font-size: 0.75vw;
                // line-height: 20px;
                // position: relative;
                // border: 2px solid green;
              }
              .mdm--recent-updates-item-id {
                // border: 1px solid red;
                width: 6vw;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: 0 0.4vw;

                span {
                  font-family: Arial;
                  font-size: 0.75vw;
                  color: #084da4;
                  margin-left: -4px;
                  border: 0;
                  white-space: nowrap;
                }
                font-size: 0.85vw;
                color: #084da4;
                // line-height: 20px;
                margin-left: 2.5rem;
                list-style-type: disc;
              }
            }
            .mdm--recent-updates-item-tag {
              margin: auto 0;
              font-family: Arial;
              font-weight: 600;
              font-size: 0.8vw;
              // line-height: 16px;
              color: #7723e0;
              background-color: #f3e7fb;
              padding: 00.25rem 0.5rem 0;
            }
          }
        }
      }
    }
  }
  &.sales-ops-sme {
    height: 59.4vh;
    .mdm--recent-updates-body {
      height: 56.5vh;
    }
    .mdm--ul-list-change-history {
      height: 44vh !important;
    }
  }
}
